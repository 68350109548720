import React, { useEffect, useState } from "react";
import $ from "jquery";
import WOW from "wowjs";
// import News from "../Shared/News";
import News from "../../Shared/News";
import { Icon } from "@iconify/react";
import { Helmet } from "react-helmet-async";
const CreativeDesign = (props) => {
  const [isACtive, setisACtive] = useState(0);

  const handleTabClick = (i) => {
    setisACtive(i);
  };

  useEffect(() => {
    const wow = new WOW.WOW({
      live: false,
    });
    wow.init();
  }, []);

  $(".projectSectiontabs li").on("click", function () {
    $(this).addClass("active").siblings().removeClass("active");
  });

  // const ourWorkLeft = [
  //   {
  //     img: "proj-1.png",
  //     projectName: `Elley's <br/> Electronics`,
  //     projectType: "Web Development",
  //     projectLink: "https://www.elleys.group/",
  //   },
  //   {
  //     img: "proj-2.png",
  //     projectName: `Goodricke`,
  //     projectType: "Web Development",
  //     projectLink: "https://www.goodricke.com/",
  //   },
  //   {
  //     img: "proj-3.png",
  //     projectName: `AYM Syntex`,
  //     projectType: "Web Development",
  //     projectLink: "https://www.aymsyntex.com/",
  //   },
  //   {
  //     img: "proj-4.png",
  //     projectName: "Induslnd Bank",
  //     projectType: "",
  //     projectLink: "",
  //   },
  //   {
  //     img: "proj-13.png",
  //     projectName: "",
  //     projectType: "",
  //     projectLink: "",
  //   },
  //   {
  //     img: "proj-14.png",
  //     projectName: "",
  //     projectType: "",
  //     projectLink: "",
  //   },
  //   {
  //     img: "proj-15.png",
  //     projectName: "",
  //     projectType: "",
  //     projectLink: "",
  //   },
  //   {
  //     img: "proj-16.png",
  //     projectName: "",
  //     projectType: "",
  //     projectLink: "",
  //   },
  //   {
  //     img: "proj-17.png",
  //     projectName: "",
  //     projectType: "",
  //     projectLink: "",
  //   },
  //   {
  //     img: "proj-18.png",
  //     projectName: "",
  //     projectType: "",
  //     projectLink: "",
  //   },
  // ];

  return (
    <>
    <Helmet>
  <title>Creative Design | Reversethought</title>
  <meta name="description" content="Reverse Thought is the best  digital marketing and advertising  agency in Mumbai, India specializes in web UI/UX design, Apps, Videos, and much more. " />
  <meta name="robots" content="max-image-preview:large" />
  <link rel="canonical" href="https://www.reversethought.com/works/creative-design" />
  <meta property="og:title" content="Creative Design | Reversethought" />
  <meta property="og:description" content="Reverse Thought is the best  digital marketing and advertising  agency in Mumbai, India specializes in web UI/UX design, Apps, Videos, and much more"/>
  <meta property="og:url" content="https://reversethought.com/works/creative-design" />
  <meta name="twitter:title" content="Creative Design | Reversethought" />
  <meta name="twitter:description" content="Reverse Thought is the best  digital marketing and advertising  agency in Mumbai, India specializes in web UI/UX design, Apps, Videos, and much more" />
    

  <meta name="google-site-verification" content="HoCjksaLBwM7qPH8Nt-Rzrqa5K02wXYiJwFSEMC4j8g" />
  <meta name="p:domain_verify" content="f307b6f44d53f755ef67d9a9a3666de7" />
  <meta name="keywords" content="top social media marketing agencies in mumbai,best digital marketing company in mumbai,online lead generation companies,top creative agency india,creative agencies in mumbai,top digital agency in mumbai 2022,digital creative agency mumbai,digital creative agency india,digital agency mumbai,creative agency in mumbai,creative agency in india,design agency in mumbai" />        
  <meta name="generator" content="All in One SEO (AIOSEO) 4.4.7.1" />
  <meta property="og:site_name" content="Creative Agency In Mumbai | Branding Agency India - Reverse Thought" />
</Helmet>

      <section className="projectsGallery">
        <div className="content_container">
          <ul className="projectSectiontabs p-0 m-0 d-flex justify-content-between align-items-center wow animate__animated animate__fadeIn">
            <a href="/works/all-projects" className="text-decoration-none mx-2">
              {" "}
              <li className="colorLightGrey fw-800 lh-1 ">All Projects</li>
            </a>
            <a href="/works/website-app" className="text-decoration-none mx-2">
              {" "}
              <li className="colorLightGrey fw-800 lh-1 ">Websites/App</li>
            </a>
            <a
              href="/works/video-production"
              className="text-decoration-none mx-2"
            >
              <li className="colorLightGrey fw-800 lh-1">Video Production</li>
            </a>
            <a
              href="/works/digital-marketing"
              className="text-decoration-none mx-2"
            >
              <li className="colorLightGrey fw-800 lh-1">Digital Marketing</li>
            </a>
            <a
              href="/works/creative-design"
              className="text-decoration-none mx-2"
            >
              {" "}
              <li className="colorLightGrey fw-800 lh-1 active">Creative Design</li>
            </a>
            <a
              href="/works/digital-activation"
              className="text-decoration-none mx-2"
            >
              {" "}
              <li className="colorLightGrey fw-800 lh-1">Digital Activations</li>
            </a>
            <a href="/works/photography" className="text-decoration-none mx-2">
              {" "}
              <li className="colorLightGrey fw-800 lh-1">Photography</li>
            </a>
          </ul>

          <div className="projectGalleryMain">
            <div className="projectsGalleryL">
              <a
                href="/aym-syntex-brochure"
                onMouseEnter={props.onMouseEnter}
                onMouseLeave={props.onMouseLeave}
              >
                <div className="project_box wow animate__animated animate__fadeInUp">
                  <img
                    src="/images/thumbnails/all_projects/ayms.webp"
                    alt="Project"
                    className="img-fluid w-100"
                  />
                  <div className="project_overview">
                    <div className="top_overview">
                      <h2 className="proj_name text-black">
                        AYM <br />
                        Syntex
                      </h2>
                      <p className="proj_type text-black">Creative Design</p>
                    </div>
                    <div className="bottom_overview">
                      <a
                        href="/aym-syntex-brochure"
                        className="unsetAll"
                        onClick={() => window.scrollTo(0, 0)}
                      >
                        <span className="commonViewAll">View Project</span>
                        <Icon icon="clarity:arrow-line" />
                      </a>
                    </div>
                  </div>
                </div>
              </a>
              <a
                href="/ljmlogo"
                onMouseEnter={props.onMouseEnter}
                onMouseLeave={props.onMouseLeave}
              >
                <div className="project_box wow animate__animated animate__fadeInUp">
                  <img
                    src="/images/thumbnails/all_projects/ljmd.png"
                    alt="Project"
                    className="img-fluid w-100"
                  />
                  <div className="project_overview">
                    <div className="top_overview">
                      <h2 className="proj_name text-black">
                        LJM Logo <br />
                      </h2>
                      <p className="proj_type text-black">Creative Design</p>
                    </div>
                    <div className="bottom_overview">
                      <a
                        href="/ljmlogo"
                        className="unsetAll"
                        onClick={() => window.scrollTo(0, 0)}
                      >
                        <span className="commonViewAll">View Project</span>
                        <Icon icon="clarity:arrow-line" />
                      </a>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div className="projectsGalleryM">
              <a
                href="/aym-syntex-annual-report"
                onMouseEnter={props.onMouseEnter}
                onMouseLeave={props.onMouseLeave}
              >
                <div className="project_box wow animate__animated animate__fadeInUp">
                  <img
                    src="/images/thumbnails/all_projects/scalling.png"
                    alt="Project"
                    className="img-fluid w-100"
                  />
                  <div className="project_overview">
                    <div className="top_overview">
                      <h2 className="proj_name text-black">
                        AYM Syntex
                        <br />
                        Annual Report
                      </h2>
                      <p className="proj_type text-black">Creative Design</p>
                    </div>
                    <div className="bottom_overview">
                      <a
                        href="/aym-syntex-annual-report"
                        className="unsetAll"
                        onClick={() => window.scrollTo(0, 0)}
                      >
                        <span className="commonViewAll">View Project</span>
                        <Icon icon="clarity:arrow-line" />
                      </a>
                    </div>
                  </div>
                </div>
              </a>
              <a
                href="/aymstall-design"
                onMouseEnter={props.onMouseEnter}
                onMouseLeave={props.onMouseLeave}
              >
                <div className="project_box wow animate__animated animate__fadeInUp">
                  <img
                    src="/images/thumbnails/all_projects/chair.png"
                    alt="Project"
                    className="img-fluid w-100"
                  />
                  <div className="project_overview">
                    <div className="top_overview">
                      <h2 className="proj_name text-black">
                        AYM Stall Design <br />
                      </h2>
                      <p className="proj_type text-black">Creative Design</p>
                    </div>
                    <div className="bottom_overview">
                      <a
                        href="/aymstall-design"
                        className="unsetAll"
                        onClick={() => window.scrollTo(0, 0)}
                      >
                        <span className="commonViewAll">View Project</span>
                        <Icon icon="clarity:arrow-line" />
                      </a>
                    </div>
                  </div>
                </div>
              </a>
              <a
                href="/aymleaflets"
                onMouseEnter={props.onMouseEnter}
                onMouseLeave={props.onMouseLeave}
              >
                <div className="project_box wow animate__animated animate__fadeInUp">
                  <img
                    src="/images/thumbnails/all_projects/aymbr.png"
                    alt="Project"
                    className="img-fluid w-100"
                  />
                  <div className="project_overview">
                    <div className="top_overview">
                      <h2 className="proj_name text-black">AYM Leaflets</h2>
                      <p className="proj_type text-black">Creative Design</p>
                    </div>
                    <div className="bottom_overview">
                      <a
                        href="/aymleaflets"
                        className="unsetAll"
                        onClick={() => window.scrollTo(0, 0)}
                      >
                        <span className="commonViewAll">View Project</span>
                        <Icon icon="clarity:arrow-line" />
                      </a>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div className="projectsGalleryR">
              <a
                href="/rsbl-calendar"
                onMouseEnter={props.onMouseEnter}
                onMouseLeave={props.onMouseLeave}
              >
                <div className="project_box wow animate__animated animate__fadeInUp">
                  <img
                    src="/images/thumbnails/all_projects/calendar.webp"
                    alt="Project"
                    className="img-fluid w-100"
                  />
                  <div className="project_overview">
                    <div className="top_overview">
                      <h2 className="proj_name text-black">RSBL Calendar</h2>
                      <p className="proj_type text-black">Crative Design</p>
                    </div>
                    <div className="bottom_overview">
                      <a
                        href="/rsbl-calendar"
                        className="unsetAll"
                        onClick={() => window.scrollTo(0, 0)}
                      >
                        <span className="commonViewAll">View Project</span>
                        <Icon icon="clarity:arrow-line" />
                      </a>
                    </div>
                  </div>
                </div>
              </a>

              <a
                href="/creative-memoria"
                onMouseEnter={props.onMouseEnter}
                onMouseLeave={props.onMouseLeave}
              >
                <div className="project_box wow animate__animated animate__fadeInUp">
                  <img
                    src="/images/thumbnails/all_projects/memo.webp"
                    alt="Project"
                    className="img-fluid w-100"
                  />
                  <div className="project_overview">
                    <div className="top_overview">
                      <h2 className="proj_name text-black">Memoria</h2>
                      <p className="proj_type text-black">Creative Design</p>
                    </div>
                    <div className="bottom_overview">
                      <a
                        href="/creative-memoria"
                        className="unsetAll"
                        onClick={() => window.scrollTo(0, 0)}
                      >
                        <span className="commonViewAll">View Project</span>
                        <Icon icon="clarity:arrow-line" />
                      </a>
                    </div>
                  </div>
                </div>
              </a>
              <a
                href="/tatacsr-report"
                onMouseEnter={props.onMouseEnter}
                onMouseLeave={props.onMouseLeave}
              >
                <div className="project_box wow animate__animated animate__fadeInUp">
                  <img
                    src="/images/thumbnails/all_projects/empower.jpg"
                    alt="Project"
                    className="img-fluid w-100"
                  />
                  <div className="project_overview">
                    <div className="top_overview">
                      <h2 className="proj_name text-black">Tata CSR Report</h2>
                      <p className="proj_type text-black">Creative Design</p>
                    </div>
                    <div className="bottom_overview">
                      <a
                        href="/tatacsr-report"
                        className="unsetAll"
                        onClick={() => window.scrollTo(0, 0)}
                      >
                        <span className="commonViewAll">View Project</span>
                        <Icon icon="clarity:arrow-line" />
                      </a>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <div className="projectGalleryMain">
            <div className="projectsGalleryL">
            <a
                href="/dodhiastall"
                onMouseEnter={props.onMouseEnter}
                onMouseLeave={props.onMouseLeave}
              >
                <div className="project_box wow animate__animated animate__fadeInUp">
                  <img
                    src="/images/thumbnails/all_projects/dodhiastall.png"
                    alt="Project"
                    className="img-fluid w-100"
                  />
                  <div className="project_overview">
                    <div className="top_overview">
                      <h2 className="proj_name text-black">
                        Dodhia Stall Design <br />
                      </h2>
                      <p className="proj_type text-black">Creative Design</p>
                    </div>
                    <div className="bottom_overview">
                      <a
                        href="/dodhiastall"
                        className="unsetAll"
                        onClick={() => window.scrollTo(0, 0)}
                      >
                        <span className="commonViewAll">View Project</span>
                        <Icon icon="clarity:arrow-line" />
                      </a>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <div className="projectsGalleryM">
            </div>
            <div className="projectsGalleryR">
            </div>
          </div>
        </div>
      </section>
      <section className="weareintheNews customPaddingClass">
        <div className="content_container">
          <News />
        </div>
      </section>
    </>
  );
};

export default CreativeDesign;

import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
import WOW from "wowjs";
import axios from "axios";
import $ from "jquery";
import { ThreeCircles } from "react-loader-spinner";
import ReCAPTCHA from "react-google-recaptcha";
import emailjs from '@emailjs/browser';
import Swal from 'sweetalert2'
import { Helmet } from "react-helmet-async";

const ContactUs = (props) => {
  const [interestedIn, setInterestedIn] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [activeItems, setActiveItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [capVal, setCapVal] = useState(null);
  const [selectedValue, setSelectedValue] = useState('');

  const items = [
    "Web Design",
    "Graphic Design",
    "Video Production",
    "UI/UX",
    "Digital Marketing",
    "Digital Activations",
    "App Development",
    "Other",
  ];

  const handleItemClick = (index) => {
    // Get the value (inner text) of the clicked item
    const selectedItem = items[index];

    // Check if the clicked item is already in the activeItems array
    const isItemActive = activeItems.includes(index);

    if (isItemActive) {
      // If the item is active, remove it from the activeItems array
      setActiveItems((prevActiveItems) =>
        prevActiveItems.filter((itemIndex) => itemIndex !== index)
      );

      // Remove the clicked item from the interestedIn array
      setInterestedIn((prevInterestedIn) =>
        prevInterestedIn
          .split(", ")
          .filter((item) => item !== selectedItem)
          .join(", ")
      );
    } else {
      // If the item is not active, add it to the activeItems array
      setActiveItems((prevActiveItems) => [...prevActiveItems, index]);

      // Add the clicked item to the interestedIn array
      setInterestedIn((prevInterestedIn) =>
        prevInterestedIn ? prevInterestedIn + ", " + selectedItem : selectedItem
      );
    }
  };

  const formRef = useRef();

  const [form, setForm] = useState
    ({
      name: '',
      c_name: '',
      email: '',
      mobile: '',
      budget: '',
      hear_abt_us: '',
      interestedIn: '',
    })

  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value })
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (form.name == '' || form.c_name == '' || form.email == '' || form.mobile == '' || form.budget == '') {
      Swal.fire
        ({
          position: 'center',
          icon: 'warning',
          title: "No invisible ink allowed",
          text: "Fill in those fields!",
          width: 'auto',
        })

      return;
    }
    setLoading(true);
    emailjs.send(
      'service_gwjflj9',
      'template_ckqjaio',
      {
        name: form.name,
        c_name: form.c_name,
        email: form.email,
        budget: form.budget,
        mobile: form.mobile,
        hear_abt_us: selectedValue,
        interestedIn: interestedIn,
      },
      'Cry1bGm7ffcQiSyHr'
    )

      .then(() => {
        setLoading(false);
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: "Thank you !",
          text: "We'll get back to you soon !",
          showConfirmButton: true,
          width: 'auto',
        })
        setSelectedValue('');
        setInterestedIn('');
        setForm(
          {
            name: '',
            c_name: '',
            email: '',
            mobile: '',
            budget: '',
            hear_abt_us: '',
            interestedIn: '',
          }
        )
      }, (error) => {
        setLoading(false);
        console.log(error);

        // alert('Uh-oh, a wild glitch appeared!')
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: "Uh-oh!",
          text: "A wild glitch appeared!",
          showCancelButton: true,
          width: 'auto',
        })
      }
      )
  }

  useEffect(() => {
    const wow = new WOW.WOW({
      live: false,
    });
    wow.init();
  }, []);

  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
  };

  return (
    <>
      <Helmet>
        <title>Contact Us | Reversethought</title>
        {/* <meta name="description" content="Contact us for any queries related to our services. Fill up the contact form and we will get back to you shortly. We will be happy to work with you!" /> */}
        <meta name="description" content="Want expert assistance and solutions? Contact us at Reverse Thought for all your queries and needs. We are here to help you with our professional team and top-notch services. " />
        <meta name="robots" content="max-image-preview:large" />
        <meta name="google-site-verification" content="HoCjksaLBwM7qPH8Nt-Rzrqa5K02wXYiJwFSEMC4j8g" />
        <meta name="p:domain_verify" content="f307b6f44d53f755ef67d9a9a3666de7" />
        <link rel="canonical" href="https://www.reversethought.com/contact-us" />
        <meta name="generator" content="All in One SEO (AIOSEO) 4.4.7.1" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:site_name" content="Creative Agency In Mumbai | Branding Agency India - Reverse Thought" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Contact Us | Reverse Thought" />
        <meta property="og:description" content="Contact us for any queries related to our services. Fill up the contact form and we will get back to you shortly. We will be happy to work with you!" />
        <meta property="og:url" content="https://reversethought.com/contact-us" />
        <meta property="article:published_time" content="2017-08-08T10:09:49+00:00" />
        <meta property="article:modified_time" content="2022-08-01T09:00:18+00:00" />
        <meta property="article:publisher" content="https://www.facebook.com/rtcspl/" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@rthoughtindia" />
        <meta name="twitter:title" content="Contact Us | Reverse Thought" />
        <meta name="twitter:description" content="Contact us for any queries related to our services. Fill up the contact form and we will get back to you shortly. We will be happy to work with you!" />
        <meta name="google" content="nositelinkssearchbox" />
       
      </Helmet>
      <section className="contactUsSection">
        <div className="content_container">
          {successMessage ? <div className="alert alert-success">{successMessage}</div> : errorMessage ? <div className="alert alert-danger">{errorMessage}</div> : ""}

          <div className="contactUsForm">
            <h1 className="colorBlack fw-800 text-center wow animate__animated animate__fadeInUp">
              Let’s Build a project together
            </h1>

            <form ref={formRef} onSubmit={handleSubmit}>
              <div className="innerForm">
                <input type="text" className="colorBlack fw-500 wow"
                  placeholder="Your Name*" name="name" required value={form.name} onChange={handleChange} />
                {/* pattern="[A-Za-z]{1,32}" */}

                <input type="text" className="colorBlack fw-500 wow"
                  placeholder="Company Name" name="c_name" value={form.c_name} onChange={handleChange} />

                <input type="email" className="colorBlack fw-500 wow"
                  placeholder="Your E-mail*" name="email" required value={form.email} onChange={handleChange} />

                <input type="number" className="colorBlack fw-500 wow"
                  placeholder="Your Mobile*" name="mobile" value={form.mobile} onChange={handleChange} pattern="[789][0-9]{9}" required />

                {/* <input type="text" placeholder="Where did you hear about Us?"
                  name="hear_abt_us"  className="colorBlack fw-500 wow" value={form.hear_abt_us} onChange={handleChange}/> */}

                <select id="dropdown" className="colorBlack fw-500 wow px-2" style={{ border: 'thin', borderBottom: '2px solid' }}
                  name="hear_abt_us" value={selectedValue} onChange={handleSelectChange}>
                  <option value="" selected disabled hidden>Where did you hear about Us?</option>
                  <option value="Bing">Bing</option>
                  <option value="Facebook">Facebook</option>
                  <option value="Google">Google</option>
                  <option value="From Friend">From Friend</option>
                  <option value="Twitter">Twitter</option>
                  <option value="Yahoo">Yahoo</option>
                  <option value="Twitter">Newspaper</option>
                  <option value="Other Source">Other Source</option>
                </select>

                {/* Display the selected value
      {selectedValue && <p>You selected: {selectedValue}</p>} */}




                <input type="number" className="colorBlack fw-500 wow"
                  placeholder="Your Budget*" name="budget" pattern="^[0-9]*[.,]?[0-9]*$"
                  required value={form.budget} onChange={handleChange} />


                <div className="interestedIn wow">
                  <p className="colorBlack fw-800">I’m Interested in ......</p>
                  <ul className="p-0 m-0 d-flex align-items-center flex-wrap" >
                    {items.map((item, index) => (
                      <li name="interestedIn" value={interestedIn}
                        key={index}
                        className={
                          activeItems.includes(index)
                            ? "colorGrey fw-800 active"
                            : "colorGrey fw-800"
                        }
                        onClick={() => handleItemClick(index)}
                      >
                        {item}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="d-flex justify-content-center align-items-center mt-5">
                <ReCAPTCHA sitekey="6Lfvg7QcAAAAABJdyVJBrzULERMTx7tESQjYerfX" size="normal" onChange={(val) => setCapVal(val)} />
              </div>
              <div className="text-center wow animate__animated animate__slideInUp">

                <button type="submit" value="Send"
                  className="colorWhite fw-800"
                  onMouseEnter={props.mouseEnterOnRed}
                  onMouseLeave={props.mouseLeaveOnRed}
                >
                  {loading ? 'Sending...' : 'Send Message'}
                </button>
              </div>
            </form>
          </div>
        </div>
        <div
          className="officeLocation"
          onMouseEnter={props.mouseEnterOnBlack}
          onMouseLeave={props.mouseLeaveOnBlack}
        >
          <div className="content_container">
            <div className="row m-0">
              <div className="col-sm-6 p-0 officeLocationL wow animate__animated animate__fadeInUp">
                <div className="location_map">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15073.548195037572!2d72.8438393!3d19.17829!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b6f0b470e419%3A0xc5ccb10923eb744c!2sReverse%20Thought%20Creative%20Studio%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1687890937795!5m2!1sen!2sin"
                    width="100%"
                    allowFullScreen=""
                    title="Location"
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
                <div className="d-flex justify-content-center align-items-center socialDiv">
                  <Link
                    to="https://www.linkedin.com/company/reverse-thought"
                    className="colorWhite fw-800 text-center" target="_blank"
                  >
                    <img src="/images/icons/ld.png" alt="LinkedIn" />
                    <p className="contact_social_icon">LinkedIn</p>
                  </Link>
                  <Link
                    to="https://www.instagram.com/reversethought/"
                    className="colorWhite fw-800 text-center" target="_blank"
                  >
                    <img src="/images/icons/yt.png" alt="Youtube" />
                    <p className="contact_social_icon">Youtube</p>
                  </Link>
                  <Link
                    to="https://www.facebook.com/rtcspl/"
                    className="colorWhite fw-800 text-center" target="_blank"
                  >
                    <img src="/images/icons/fb.png" alt="Facebook" />
                    <p className="contact_social_icon">Facebook</p>
                  </Link>
                </div>
              </div>
              <div className="col-sm-6 pe-0 officeLocationR">
                <div className="officeLocationDetails">
                  <h4 className="colorWhite fw-800 wow animate__animated animate__fadeInUp animation_delay_100ms">
                    Office Address
                  </h4>
                  <p className="colorWhite fw-400 wow animate__animated animate__fadeInUp animation_delay_200ms">
                    305, 3rd Floor, Lotus Business Park, <br />Rambagh Lane,  S.V. Road, <br /> Malad
                    West, <br />
                    Mumbai-400064, India.
                  </p>
                </div>
                <div className="officeLocationDetails">
                  <h4 className="colorWhite fw-800 wow animate__animated animate__fadeInUp animation_delay_300ms">
                    Contact
                  </h4>
                  <a
                    href="tel:+91 9930658107"
                    className="colorWhite fw-400 wow animate__animated animate__fadeInUp animation_delay_400ms"
                  >
                    +91 9930658107 (Tushar)
                  </a>
                  <a
                    href="tel:+91 9769733134"
                    className="colorWhite fw-400 wow animate__animated animate__fadeInUp animation_delay_400ms"
                  >
                    +91 9769733134 (Sunidhi)
                  </a>
                  <a
                    href="tel:+91 8291862924"
                    className="colorWhite fw-400 wow animate__animated animate__fadeInUp animation_delay_400ms"
                  >
                    +91 8291862924 (Office)
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="contactDetails">
          <div className="marqueSlide d-flex">
            <a
              href="mailto:contact@reversethought.com"
              className="colorBlack fw-500"
            >
              contact@reversethought.com
            </a>
            <a
              href="mailto:contact@reversethought.com"
              className="colorRed fw-500"
            >
              contact@reversethought.com
            </a>
            <a
              href="mailto:contact@reversethought.com"
              className="colorBlack fw-500"
            >
              contact@reversethought.com
            </a>
            <a
              href="mailto:contact@reversethought.com"
              className="colorRed fw-500"
            >
              contact@reversethought.com
            </a>
            <a
              href="mailto:contact@reversethought.com"
              className="colorBlack fw-500"
            >
              contact@reversethought.com
            </a>
            <a
              href="mailto:contact@reversethought.com"
              className="colorRed fw-500"
            >
              contact@reversethought.com
            </a>
            <a
              href="mailto:contact@reversethought.com"
              className="colorBlack fw-500"
            >
              contact@reversethought.com
            </a>
            <a
              href="mailto:contact@reversethought.com"
              className="colorRed fw-500"
            >
              contact@reversethought.com
            </a>
            <a
              href="mailto:contact@reversethought.com"
              className="colorBlack fw-500"
            >
              contact@reversethought.com
            </a>
            <a
              href="mailto:contact@reversethought.com"
              className="colorRed fw-500"
            >
              contact@reversethought.com
            </a>
            <a
              href="mailto:contact@reversethought.com"
              className="colorBlack fw-500"
            >
              contact@reversethought.com
            </a>
            <a
              href="mailto:contact@reversethought.com"
              className="colorRed fw-500"
            >
              contact@reversethought.com
            </a>
            <a
              href="mailto:contact@reversethought.com"
              className="colorBlack fw-500"
            >
              contact@reversethought.com
            </a>
            <a
              href="mailto:contact@reversethought.com"
              className="colorRed fw-500"
            >
              contact@reversethought.com
            </a>
          </div>
          <div className="content_container">
            <div className="ourNewsLetter text-center">
              <h4 className="colorBlack fw-800 wow animate__animated animate__fadeInUp">
                Stay updated with Reverse thought <br /> by signing up for our
                newsletter.
              </h4>
              <p className="colorBlack fw-400 wow animate__animated animate__fadeIn animation_delay_200ms">
                We'll keep you in the loop with everything good going on in the
                modern working world.
              </p>
              <form className="newsLetterInput d-flex align-items-center justify-content-center wow animate__animated animate__fadeIn animation_delay_300ms">
                <input
                  type="email"
                  placeholder="example@reversethought.com"
                  required
                />
                <button>
                  <Icon icon="maki:arrow" color="white" />
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
      {isLoading && (
        <>
          <div className="spinnerCss"></div>
          <ThreeCircles
            height="100"
            width="100"
            color="#fff"
            wrapperStyle={{}}
            wrapperClass="inner_spinner_css"
            visible={true}
            ariaLabel="three-circles-rotating"
            outerCircleColor=""
            innerCircleColor=""
            middleCircleColor=""
          />
        </>
      )}
    </>
  );
};

export default ContactUs;

import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Icon } from "@iconify/react";
import WOW from "wowjs";

const BlogData = (props) => {
    useEffect(() => {

    
  }, []);
 
 
  const [postsData, setPostsData] = useState(null);
  const [error, setError] = useState(null);
  const [showAllBlogs, setShowAllBlogs] = useState(false);
  useEffect(() => {
    

    const fetchPostsData = async () => {
      try {
        // await new Promise(resolve => setTimeout(resolve, 5000));
        const response = await fetch('https://www.reversethought.com/site2023/wp-json/wp/v2/posts?_embed');
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const data = await response.json();

        // Ensure the data is an array
        if (!Array.isArray(data)) {
          throw new Error('Invalid data format');
        }

        setPostsData(data);
      } catch (error) {
        console.error('Error fetching posts data:', error);
        setError(error.message);
      }

      const wow = new WOW.WOW({});
      wow.init();
      
    };

    fetchPostsData();

  }, 8000);

  if (error) {
    return <div>Error loading posts data: {error}</div>;
  }

  if (!postsData) {
    return <div></div>; // or another loading indicator
  }

  const formatDate = (dateString) => {
    const options = { day: 'numeric', month: 'short' };
    const formattedDate = new Date(dateString).toLocaleDateString('en-US', options);
    return formattedDate;
  };

  const decodeEntities = (html) => {
    var txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.textContent || txt.innerText;
  };
  const getContent = (content, start, end) => {
    const plainTextContent = content.replace(/<[^>]*>/g, '');
    // Split the plain text content into words
    const words = plainTextContent.split(/\s+/);

    const startingContent = words.slice(start, end).join(' ');
    return startingContent;
  };
  const visiblePosts = showAllBlogs ? postsData : postsData.slice(0, 3);
  return (
    <>
          <div className="gridSystem blogGridSystem">
           {visiblePosts.map((post) => (
              <div className="gridSystemInner wow my-3">
                <a href={post.link}>{post._embedded && post._embedded['wp:featuredmedia'] && (
                  <img src={post._embedded['wp:featuredmedia'][0].source_url} alt="blog" className="w-100 blog_img" />)}
                </a>
                {post.date && (<h5 className="colorGrey fw-800"> {formatDate(post.date)}</h5>)}
                <a style={{ textDecoration: 'none' }} href={post.link}> <h4 className="colorBlack fw-800" >
                  {decodeEntities(post.title.rendered)}
                </h4>    </a>
                <a style={{ textDecoration: 'none', color: 'black' }} href={post.link}> <p className="colorBlack fw-400">{getContent(post.content.rendered, 0, 25)}</p></a>
              </div>
            ))}
          </div>
       
          {!showAllBlogs && postsData.length > 3 && (
            <Link
              to="https://www.reversethought.com/site2023/blogs/"
              className="unsetAll"
              onClick={() => window.scrollTo(0, 0)}
              onMouseEnter={props.onMouseEnterSmall}
              onMouseLeave={props.onMouseLeaveSmall}
            >
              <span className="commonViewAll pt-10">View More Blogs</span>
              <Icon icon="clarity:arrow-line" />
            </Link>
          )} 
       
     
    
    
    </>
  );
};
export default BlogData;

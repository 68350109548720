import React, { useEffect } from "react";
import { Accordion } from "react-bootstrap-accordion";
import { Link } from "react-router-dom";
import WOW from "wowjs";
import News from "../Shared/News";
import { Helmet } from "react-helmet-async";

const Careers = () => {
  useEffect(() => {
    const wow = new WOW.WOW({
      live: false,
    });
    wow.init();
  }, []);

  return (
    <>

<Helmet>
  <title>Careers | Reversethought</title>
  {/* <meta name="description" content="Learn how Reverse Thought's privacy policy ensures the protection of your valuable data. Discover our commitment to safeguarding your privacy and maintaining transparency." />                           */}
  <link rel="canonical" href="https://www.reversethought.com/careers"/>
  <meta property="og:title" content="Careers | Reversethought" />
  {/* <meta property="og:description" content="Learn how Reverse Thought's privacy policy ensures the protection of your valuable data. Discover our commitment to safeguarding your privacy and maintaining transparency."/> */}
  <meta property="og:url" content="https://www.reversethought.com/careers" />
  <meta name="twitter:title" content="Careers | Reversethought" />
  {/* <meta name="twitter:description" content="Learn how Reverse Thought's privacy policy ensures the protection of your valuable data. Discover our commitment to safeguarding your privacy and maintaining transparency." /> */}
    

  <meta name="google-site-verification" content="HoCjksaLBwM7qPH8Nt-Rzrqa5K02wXYiJwFSEMC4j8g" />
  <meta name="p:domain_verify" content="f307b6f44d53f755ef67d9a9a3666de7" />
  <meta name="keywords" content="top social media marketing agencies in mumbai,best digital marketing company in mumbai,online lead generation companies,top creative agency india,creative agencies in mumbai,top digital agency in mumbai 2022,digital creative agency mumbai,digital creative agency india,digital agency mumbai,creative agency in mumbai,creative agency in india,design agency in mumbai" />        
  <meta name="generator" content="All in One SEO (AIOSEO) 4.4.7.1" />
  <meta property="og:site_name" content="Creative Agency In Mumbai | Branding Agency India - Reverse Thought" />
</Helmet>

      <section className="aboitUsSection clientsMainSection careersPageCustomPaddingBottom position-relative">
        <div className="content_container">
          <div className="servicePageBanner">
            <h1 className="colorBlack fw-800 projectHeading wow animate__animated animate__fadeInUp">
              Hey, want to join our <br /> tribe ?
            </h1>
            <p className="colorBlack fw-400 projectDesc wow animate__animated animate__fadeInUp animation_delay_300ms">
              <span className="colorRed fw-400">
                As a young and imaginative company,
              </span>
             <span> Reverse Thought has been rapidly growing with an unrelenting hunger
              to achieve more, be more, and do more.</span> 
            </p>
          </div>
        </div>
      </section>

      <section className="careersAccordian">
        <div className="content_container wow animate__animated animate__slideInUp animation_delay_200ms">
          <Accordion
            title={
              <>
                <div className="d-flex justify-content-between align-items-center w-100">
                  <h4 className="accordianTitle colorBlack fw-800">
                    Marketing Executive
                  </h4>
                </div>
                <Link
                  to="https://forms.gle/pQxV4mXTFqhCBaqU6" target="_blank"
                  className="unsetAll"
                  onClick={() => window.scrollTo(0, 0)}
                >
                  <button className="applyToJob colorWhite fw-800">
                    Apply Now
                  </button>
                </Link>
              </>
            }
          >
            <div className="position-relative">
              {/* <button className="applyToJob">Apply Now</button> */}
              <div className="d-flex customGap">
                <div>
                  <span className="colorBlack fw-800">Location</span>
                  <p className="accordianDesc colorBlack fw-400">
                    Mumbai, Remote
                  </p>
                </div>
                <div>
                  <span className="colorBlack fw-800">Job Type</span>
                  <p className="accordianDesc colorBlack fw-400">Permanent</p>
                </div>
              </div>
              <p className="accordianDesc colorBlack fw-400">
                Web development involves development of e-commerce websites,
                content management systems and promotional websites with
                functionality as required. Candidate should be able to map the
                flow and plan the project on hands-on basis.
              </p>
              <div className="sectionHeadandDesc">
                <h4 className="accordianTitle colorBlack fw-800">
                  What you'll do
                </h4>
                {/* <ul className="m-0">
                  <li className="accordianDesc colorBlack fw-400">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    Ut enim ad minim.
                  </li>
                  <li className="accordianDesc colorGrey fw-400">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    Ut enim ad minim.
                  </li>
                </ul> */}
              </div>

              {/* <div className="sectionHeadandDesc">
                <h4 className="accordianTitle colorBlack fw-800">What You are</h4>
                <ul className="p-0 m-0">
                  <li className="accordianDesc colorBlack fw-400">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    Ut enim ad minim.
                  </li>
                  <li className="accordianDesc colorGrey fw-400">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    Ut enim ad minim.
                  </li>
                </ul>
              </div> */}
            </div>
          </Accordion>

          {/* REMOVED -- 2 Same Acoordian content --  03/01/24 */}

        </div>
        <div className="content_container wow animate__animated animate__slideInUp animation_delay_200ms">
          <Accordion
            title={
              <>
                <div className="d-flex justify-content-between align-items-center w-100">
                  <h4 className="accordianTitle colorBlack fw-800">
                  Web Developer
                  </h4>
                </div>
                <Link
                  to="https://forms.gle/pQxV4mXTFqhCBaqU6" target="_blank"
                  className="unsetAll"
                  onClick={() => window.scrollTo(0, 0)}
                >
                  <button className="applyToJob colorWhite fw-800">
                    Apply Now
                  </button>
                </Link>
              </>
            }
          >
            <div className="position-relative">
              {/* <button className="applyToJob">Apply Now</button> */}
              <div className="d-flex customGap">
                <div>
                  <span className="colorBlack fw-800">Location</span>
                  <p className="accordianDesc colorBlack fw-400">
                    Mumbai, Remote
                  </p>
                </div>
                <div>
                  <span className="colorBlack fw-800">Job Type</span>
                  <p className="accordianDesc colorBlack fw-400">Permanent</p>
                </div>
              </div>
              <p className="accordianDesc colorBlack fw-400">
              Web development involves development of e-commerce websites, content management systems and promotional websites with functionality as required. Candidate should be able to map the flow and plan the project on hands-on basis.
              </p>
              <div className="sectionHeadandDesc">
                <h4 className="accordianTitle colorBlack fw-800">
                Candidate Requirements
                </h4>
                 <ul className="m-0">
                  <li className="accordianDesc colorBlack fw-400">Experience in working with Core PHP & MySql</li>
                  <li className="accordianDesc colorGrey fw-400">Reasonable proficiency in Ajax, Jquery and Javascript</li>
                  <li className="accordianDesc colorGrey fw-400">Experience of minimum one project in creating shopping cart</li>
                  <li className="accordianDesc colorGrey fw-400">payment gateway integration & Content Management System</li>
                </ul> 
              </div>

              <div className="sectionHeadandDesc">
                <h4 className="accordianTitle colorBlack fw-800">What You are</h4>
                             {/*  <ul className="p-0 m-0">
                  <li className="accordianDesc colorBlack fw-400">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    Ut enim ad minim.
                  </li>
                  <li className="accordianDesc colorGrey fw-400">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    Ut enim ad minim.
                  </li>
                </ul>*/}
              </div> 
            </div>
          </Accordion>

          {/* REMOVED -- 2 Same Acoordian content --  03/01/24 */}

        </div>
        <div className="content_container wow animate__animated animate__slideInUp animation_delay_200ms">
          <Accordion
            title={
              <>
                <div className="d-flex justify-content-between align-items-center w-100">
                  <h4 className="accordianTitle colorBlack fw-800">
                  Social Media Internship
                  </h4>
                </div>
                <Link
                  to="https://forms.gle/pQxV4mXTFqhCBaqU6" target="_blank"
                  className="unsetAll"
                  onClick={() => window.scrollTo(0, 0)}
                >
                  <button className="applyToJob colorWhite fw-800">
                    Apply Now
                  </button>
                </Link>
              </>
            }
          >
            <div className="position-relative">
              {/* <button className="applyToJob">Apply Now</button> */}
              <div className="d-flex customGap">
                <div>
                  <span className="colorBlack fw-800">Location</span>
                  <p className="accordianDesc colorBlack fw-400">
                    Mumbai, Remote
                  </p>
                </div>
                <div>
                  <span className="colorBlack fw-800">Job Type</span>
                  <p className="accordianDesc colorBlack fw-400">Permanent</p>
                </div>
              </div>
              <p className="accordianDesc colorBlack fw-400">
              There are all forms of marketing and then there’s social media. We view social media interns as professionals who have the ability to create meaningful opportunities for them as well as the company. Therefore, just throwing up a fan page is not what we would like them to limit themselves to. There is a growing interest among businesses to reach and expand their audiences, and our interns help them out in a way that is familiar and fun. The important skills we look for are good verbal and written communication skills and the ability to collect and analyse data efficiently.
              </p>
             <div className="sectionHeadandDesc">
                <h4 className="accordianTitle colorBlack fw-800">
                Candidate Requirements
                </h4>
               {/*    <ul className="m-0">
                  <li className="accordianDesc colorBlack fw-400">Experience in working with Core PHP & MySql</li>
                  <li className="accordianDesc colorGrey fw-400">Reasonable proficiency in Ajax, Jquery and Javascript</li>
                  <li className="accordianDesc colorGrey fw-400">Experience of minimum one project in creating shopping cart</li>
                  <li className="accordianDesc colorGrey fw-400">payment gateway integration & Content Management System</li>
                </ul> 
              </div>

              <div className="sectionHeadandDesc">
                <h4 className="accordianTitle colorBlack fw-800">What You are</h4> */}
                             {/*  <ul className="p-0 m-0">
                  <li className="accordianDesc colorBlack fw-400">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    Ut enim ad minim.
                  </li>
                  <li className="accordianDesc colorGrey fw-400">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    Ut enim ad minim.
                  </li>
                </ul>*/}
              </div> 
            </div>
          </Accordion>

          {/* REMOVED -- 2 Same Acoordian content --  03/01/24 */}

        </div>
        <div className="content_container wow animate__animated animate__slideInUp animation_delay_200ms">
          <Accordion
            title={
              <>
                <div className="d-flex justify-content-between align-items-center w-100">
                  <h4 className="accordianTitle colorBlack fw-800">
                  Web Development Internship
                  </h4>
                </div>
                <Link
                  to="https://forms.gle/pQxV4mXTFqhCBaqU6" target="_blank"
                  className="unsetAll"
                  onClick={() => window.scrollTo(0, 0)}
                >
                  <button className="applyToJob colorWhite fw-800">
                    Apply Now
                  </button>
                </Link>
              </>
            }
          >
            <div className="position-relative">
              {/* <button className="applyToJob">Apply Now</button> */}
              <div className="d-flex customGap">
                <div>
                  <span className="colorBlack fw-800">Location</span>
                  <p className="accordianDesc colorBlack fw-400">
                    Mumbai, Remote
                  </p>
                </div>
                <div>
                  <span className="colorBlack fw-800">Job Type</span>
                  <p className="accordianDesc colorBlack fw-400">Permanent</p>
                </div>
              </div>
              <p className="accordianDesc colorBlack fw-400">
              We work with Core PHP and MySql along with the use of Ajax and Javascript based on individual project requirements. We are always open to training candidates who have the opportunity to join us as developers eventually.</p>
              <p className="accordianDesc colorBlack fw-400">
The projects primarily include E-commerce websites, functionality driven websites, Content Management Systems, among others that provide exposure to real-life challenges while handling any project. The senior developers with years of experience behind them guide their designated trainees and groom them into leaders who can plan and spearhead projects.
              </p>
             <div className="sectionHeadandDesc">
                <h4 className="accordianTitle colorBlack fw-800">
                Candidate Requirements
                </h4>
               {/*    <ul className="m-0">
                  <li className="accordianDesc colorBlack fw-400">Experience in working with Core PHP & MySql</li>
                  <li className="accordianDesc colorGrey fw-400">Reasonable proficiency in Ajax, Jquery and Javascript</li>
                  <li className="accordianDesc colorGrey fw-400">Experience of minimum one project in creating shopping cart</li>
                  <li className="accordianDesc colorGrey fw-400">payment gateway integration & Content Management System</li>
                </ul> 
              </div>

              <div className="sectionHeadandDesc">
                <h4 className="accordianTitle colorBlack fw-800">What You are</h4> */}
                             {/*  <ul className="p-0 m-0">
                  <li className="accordianDesc colorBlack fw-400">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    Ut enim ad minim.
                  </li>
                  <li className="accordianDesc colorGrey fw-400">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    Ut enim ad minim.
                  </li>
                </ul>*/}
              </div> 
            </div>
          </Accordion>

          {/* REMOVED -- 2 Same Acoordian content --  03/01/24 */}

        </div>
      </section>
     

      <section className="weareintheNews">
        <div className="content_container">
          <News />
        </div>
      </section>
    </>
  );
};

export default Careers;

import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import WOW from "wowjs";
import { Icon } from "@iconify/react";
import { Helmet } from "react-helmet-async";

const Konvertors = (props) => {
  useEffect(() => {
    const wow = new WOW.WOW({
      live: false,
    });
    wow.init();
  }, []);

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
 <Helmet>
    <title>Konvertors | Reversethought </title>
    <meta name="description" content="We will provide professionally shot pictures that attracts attention, profile shoots, corporate shoots and also drone assisted photography.Visit us to avail our services." />
    <meta name="robots" content="max-image-preview:large" />
    <meta name="google-site-verification" content="HoCjksaLBwM7qPH8Nt-Rzrqa5K02wXYiJwFSEMC4j8g" />
    <meta name="p:domain_verify" content="f307b6f44d53f755ef67d9a9a3666de7" />
    <meta name="keywords" content="still photography" />
    <link rel="canonical" href="https://www.reversethought.com/commercial" />
    <meta name="generator" content="All in One SEO (AIOSEO) 4.4.7.1" />
    <meta property="og:locale" content="en_US" />
    <meta property="og:site_name" content="Creative Agency In Mumbai | Branding Agency India - Reverse Thought" />
    <meta property="og:type" content="article" />
    <meta property="og:title" content="Commercial | Reversethought" />
    <meta property="og:description" content="We will provide professionally shot pictures that attracts attention, profile shoots, corporate shoots and also drone assisted photography. Our mantra is Showcase, Attract &amp; Impress." />
    <meta property="og:url" content="https://www.reversethought.com/commercial" />
    <meta property="article:tag" content="Commercial | Reversethought" />
    <meta property="article:published_time" content="2018-12-26T08:39:37+00:00" />
    <meta property="article:modified_time" content="2023-07-12T12:57:24+00:00" />
    <meta property="article:publisher" content="https://www.facebook.com/rtcspl/" />
    <meta name="twitter:card" content="summary" />
    <meta name="twitter:site" content="@rthoughtindia" />
    <meta name="twitter:title" content="Commercial | Reversethought" />
    <meta name="twitter:description" content="We will provide professionally shot pictures that attracts attention, profile shoots, corporate shoots and also drone assisted photography. Our mantra is Showcase, Attract &amp; Impress." />
    <meta name="google" content="nositelinkssearchbox" />
</Helmet>

      <section className="ourWork">
        <div className="content_container">
          <h1 className="projectHeading colorBlack fw-800 text-center wow animate__animated animate__fadeIn">
            Konvertors Factory
          </h1>
          <img
            src="/images/works/photography/konvertors/main.jpg"
            alt="Commercial"
            className="img-fluid wow animate__animated animate__fadeIn"
          />
          <div className="gridSystem">
            <div className="gridSystemInner">
              {/* <h2 className="colorRed fw-800 wow animate__animated animate__fadeIn">     ---- 02/01/24
                Brief
              </h2>
              <p className="colorGrey fw-400 wow animate__animated animate__fadeInLeft">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur.
              </p> */}
              <div className="row m-0 briefDetails wow animate__animated animate__fadeIn">
                <div className="col-sm-6 p-0 briefDetailsInner wow animate__animated animate__fadeInLeft">
                  <h4 className="colorBlack fw-800">Client</h4>
                  <span className="colorGrey fw-400">Konvertors</span>
                </div>
                <div className="col-sm-6 p-0 briefDetailsInner wow animate__animated animate__fadeInLeft">
                  <h4 className="colorBlack fw-800">Industry</h4>
                  <span className="colorGrey fw-400">Packaging</span>
                </div>
                <div className="col-sm-6 p-0 briefDetailsInner wow animate__animated animate__fadeInRight">
                  <h4 className="colorBlack fw-800">Work</h4>
                  <span className="colorGrey fw-400">Photography</span>
                </div>
              </div>
            </div>
            <div className="gridSystemInner">
              <div className="d-flex customGap">
                <img
                  src="/images/works/photography/konvertors/small.jpg"
                  alt="photography"
                  className="img-fluid w-100 wow animate__animated animate__fadeInRight"
                />
                {/* <img
                  src="/images/works/elleys-2.jpg"
                  alt="elleys-2"
                  className="img-fluid w-100 wow animate__animated animate__fadeInRight animate__delay-0.9s"
                />
                <img
                  src="/images/works/elleys-3.jpg"
                  alt="elleys-3"
                  className="img-fluid w-100 wow animate__animated animate__fadeInRight"
                /> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className=" serviceImageSection"
        onMouseEnter={props.mouseEnterOnBlack}
        onMouseLeave={props.mouseLeaveOnBlack}
      >
        <div className="content_container">
          <div className="row m-0">
            <div className="col-12 p-0 fullWidth wow animate__animated animate__slideInDown">
              <img
                src="/images/works/photography/konvertors/big2.jpg"
                alt="service1"
                className="w-100 "
              />
              <img
                src="/images/works/photography/konvertors/big1.jpg"
                alt="service1"
                className="w-100 my-3 "
              />
              <img
                src="/images/works/photography/konvertors/big3.jpg"
                alt="service1"
                className="w-100 my-3 "
              />
            </div>
          </div>
        </div>
      </section>

      <section className="ourWorkSlider wow animate__animated animate__slideInLeft">
        <div className="content_container">
          <h2 className="sectionHead colorBlack wow fw-800">
            <img src="/images/icons/line.jpg" alt="line" />
            More Works
          </h2>
          <Slider {...settings}>
            <div>
              <div className="sliderInner">
              <a href="/markhome-photography2024" className="text-decoration-none">
                <div className="imgHoverEffect position-relative">
                  <a
                    href="/markhome-photography2024"
                    className="colorWhite fw-800"
                    onMouseEnter={props.onMouseEnterSmall}
                    onMouseLeave={props.onMouseLeaveSmall}
                  >
                    View Projects &nbsp;
                    <img
                      src="/images/icons/topArrowWhite.svg"
                      alt="toparrow"
                      className="img-fluid"
                    />
                  </a>
                  <img src="/images/commercial.png" alt="work" />
                </div>
                <h2 className="colorBlack fw-800">Mark Home</h2>
                <span className="colorGrey fw-400">Photography</span>
                </a>
              </div>
            </div>
            {/* <div>
              <div className="sliderInner">
                <div className="imgHoverEffect position-relative">
                  <a
                    href="#"
                    className="colorWhite fw-800"
                    onMouseEnter={props.onMouseEnterSmall}
                    onMouseLeave={props.onMouseLeaveSmall}
                  >
                    View Projects &nbsp;
                    <img
                      src="/images/icons/topArrowWhite.svg"
                      alt="toparrow"
                      className="img-fluid"
                    />
                  </a>
                  <img
                    src="/images/works/digital_activation/alkemar/Maskgroup2.jpg"
                    alt="work"
                  />
                </div>
                <h2 className="colorBlack fw-800">AR Filters</h2>
                <span className="colorGrey fw-400">
                  AR Filter Design & Development
                </span>
              </div>
            </div> */}
            {/* <div>
              <div className="sliderInner">
                <div className="imgHoverEffect position-relative">
                  <a
                    href="#"
                    className="colorWhite fw-800"
                    onMouseEnter={props.onMouseEnterSmall}
                    onMouseLeave={props.onMouseLeaveSmall}
                  >
                    View Projects &nbsp;
                    <img
                      src="/images/icons/topArrowWhite.svg"
                      alt="toparrow"
                      className="img-fluid"
                    />
                  </a>
                  <img
                    src="/images/works/digital_activation/alkemar/Maskgroup1.jpg"
                    alt="work"
                  />
                </div>
                <h2 className="colorBlack fw-800">Canadian Woods</h2>
                <span className="colorGrey fw-400">
                  Ar Filter Design & Development
                </span>
              </div>
            </div>
            <div>
              <div className="sliderInner">
                <div className="imgHoverEffect position-relative">
                  <a
                    href="#"
                    className="colorWhite fw-800"
                    onMouseEnter={props.onMouseEnterSmall}
                    onMouseLeave={props.onMouseLeaveSmall}
                  >
                    View Projects &nbsp;
                    <img
                      src="/images/icons/topArrowWhite.svg"
                      alt="toparrow"
                      className="img-fluid"
                    />
                  </a>
                  <img src="/images/work.png" alt="work" />
                </div>
                <h2 className="colorBlack fw-800">Malikh Architecture</h2>
                <span className="colorGrey fw-400">Website Design</span>
              </div>
            </div> */}
          </Slider>
          <Link
            to="/works"
            className="unsetAll"
            onClick={() => window.scrollTo(0, 0)}
            onMouseEnter={props.onMouseEnterSmall}
            onMouseLeave={props.onMouseLeaveSmall}
          >
            <span className="commonViewAll">View Our Projects</span>
            <Icon icon="clarity:arrow-line" />
          </Link>
        </div>
      </section>
    </>
  );
};

export default Konvertors;

import React, { useEffect} from "react";
import WOW from "wowjs";

function News() {
  useEffect(() => {
    const wow = new WOW.WOW({
      live: false,
    });
    wow.init();
  }, []);

  return (
    <>
      <h3 className="sectionHead colorBlack fw-800 wow">
        <img src="/images/icons/line.svg" alt="line" />
        We are in the News
      </h3>
      <div className="gridSystem">
        <img
          src="/images/news/news-7.png"
          alt="news"
          className="img-fluid wow news_img"
          data-aos="true"
          data-aos-offset="321.6666666666667"
        />
        <img
          src="/images/news/news-2.png"
          alt="news"
          className="img-fluid wow news_img"
          data-aos="true"
          data-aos-offset="321.6666666666667"
        />
        <img
          src="/images/news/news-5.png"
          alt="news"
          className="img-fluid wow news_img"
          data-aos="true"
          data-aos-offset="321.6666666666667"
        />
        <img
          src="/images/news/news-1.png"
          alt="news"
          className="img-fluid wow news_img"
          data-aos="true"
          data-aos-offset="321.6666666666667"
        />
        <img
          src="/images/news/news-3.png"
          alt="news"
          className="img-fluid wow news_img"
          data-aos="true"
          data-aos-offset="321.6666666666667"
        />
        <img
          src="/images/news/news-4.jpg"
          alt="news"
          className="img-fluid wow news_img"
          data-aos="true"
          data-aos-offset="321.6666666666667"
        />
        {/* <img
          src="/images/news/news-7.jpg"
          alt="news"
          className="img-fluid wow news_img"
          data-aos="true"
          data-aos-offset="321.6666666666667"
        /> */}
        <img
          src="/images/news/news-6.png"
          alt="news"
          className="img-fluid wow news_img"
          data-aos="true"
          data-aos-offset="321.6666666666667"
        />
        <img
          src="/images/news/news-9.png"
          alt="news"
          className="img-fluid wow news_img"
          data-aos="true"
          data-aos-offset="321.6666666666667"
        />
      </div>
    </>
  );
}

export default News;

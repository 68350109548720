import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import WOW from "wowjs";
import { Icon } from "@iconify/react";
import { Helmet } from "react-helmet-async";
const LjmLogo = (props) => {
  useEffect(() => {
    const wow = new WOW.WOW({
      live: false,
    });
    wow.init();
  }, []);

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
  
  <Helmet>
  <title>LJM Logo | Reversethought</title>
  <meta name="description" content="Reverse Thought is the best  digital marketing and advertising  agency in Mumbai, India specializes in web UI/UX design, Apps, Videos, and much more. " />
  <meta name="robots" content="max-image-preview:large" />
  <link rel="canonical" href="https://www.reversethought.com/ljmlogo" />
  <meta property="og:title" content="LJM Logo | Reversethought" />
  <meta property="og:description" content="Reverse Thought is the best  digital marketing and advertising  agency in Mumbai, India specializes in web UI/UX design, Apps, Videos, and much more"/>
  <meta property="og:url" content="https://www.reversethought.com/ljmlogo" />
  <meta name="twitter:title" content="LJM Logo | Reversethought" />
  <meta name="twitter:description" content="Reverse Thought is the best  digital marketing and advertising  agency in Mumbai, India specializes in web UI/UX design, Apps, Videos, and much more" />
    
  <meta name="google-site-verification" content="HoCjksaLBwM7qPH8Nt-Rzrqa5K02wXYiJwFSEMC4j8g" />
  <meta name="p:domain_verify" content="f307b6f44d53f755ef67d9a9a3666de7" />
  <meta name="keywords" content="top social media marketing agencies in mumbai,best digital marketing company in mumbai,online lead generation companies,top creative agency india,creative agencies in mumbai,top digital agency in mumbai 2022,digital creative agency mumbai,digital creative agency india,digital agency mumbai,creative agency in mumbai,creative agency in india,design agency in mumbai" />        
  <meta name="generator" content="All in One SEO (AIOSEO) 4.4.7.1" />
  <meta property="og:site_name" content="Creative Agency In Mumbai | Branding Agency India - Reverse Thought" />
</Helmet>

      <section className="ourWork">
        <div className="content_container">
          <h1 className="projectHeading colorBlack fw-800 text-center wow animate__animated animate__fadeIn">
            LJM Logo
          </h1>
          <img
            src="/images/works/creative_design/ljm/first.jpg"
            alt="LjmLogo"
            className="img-fluid wow animate__animated animate__fadeIn"
          />
          <div className="gridSystem">
            <div className="gridSystemInner">
              {/* <h2 className="colorRed fw-800 wow animate__animated animate__fadeIn">    ---- 02/01/24
                Brief
              </h2>
              <p className="colorGrey fw-400 wow animate__animated animate__fadeInLeft">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur.
              </p> */}
              <div className="row m-0 briefDetails wow animate__animated animate__fadeIn">
                <div className="col-sm-6 p-0 briefDetailsInner wow animate__animated animate__fadeInLeft">
                  <h4 className="colorBlack fw-800">Client</h4>
                  <span className="colorGrey fw-400">LJM Logo </span>
                </div>
                <div className="col-sm-6 p-0 briefDetailsInner wow animate__animated animate__fadeInLeft">
                  <h4 className="colorBlack fw-800">Industry</h4>
                  <span className="colorGrey fw-400">Corporate</span>
                </div>
                <div className="col-sm-6 p-0 briefDetailsInner wow animate__animated animate__fadeInRight">
                  <h4 className="colorBlack fw-800">Work</h4>
                  <span className="colorGrey fw-400">Creative Design</span>
                </div>
              </div>
            </div>
            <div className="gridSystemInner">
              <div className="d-flex customGap">
                <img
                  src="/images/works/creative_design/ljm/second.jpg"
                  alt="LjmLogo"
                  className="img-fluid w-100 wow animate__animated animate__fadeInRight"
                />
                {/* <img
                  src="/images/works/elleys-2.jpg"
                  alt="elleys-2"
                  className="img-fluid w-100 wow animate__animated animate__fadeInRight animate__delay-0.9s"
                />
                <img
                  src="/images/works/elleys-3.jpg"
                  alt="elleys-3"
                  className="img-fluid w-100 wow animate__animated animate__fadeInRight"
                /> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className=" serviceImageSection"
        onMouseEnter={props.mouseEnterOnBlack}
        onMouseLeave={props.mouseLeaveOnBlack}
      >
        <div className="content_container">
          <div className="row m-0">
            <div className="col-12 p-0 fullWidth wow animate__animated animate__slideInDown">
              <img
                src="/images/works/creative_design/ljm/third.jpg"
                alt="service1"
                className="w-100 "
              />
            </div>
            {/* <div className="col-sm-6 p-0 leftImg wow animate__animated animate__slideInLeft">
              <img
                src="/images/works/service2.jpg"
                alt="service2"
                className="w-100 h-100" 
              />
            </div>
            <div className="col-sm-6 p-0 rightImg wow animate__animated animate__slideInRight">
              <img
                src="/images/works/service3.jpg"
                alt="service3"
                className="w-100"
              />
              <img
                src="/images/works/service4.jpg"
                alt="service4"
                className="w-100"
              />
            </div> */}
          </div>
        </div>
      </section>

      <section className="ourWorkSlider wow animate__animated animate__slideInLeft">
        <div className="content_container">
          <h2 className="sectionHead colorBlack fw-800 wow animate__animated animate__fadeIn">
            <img src="/images/icons/line.jpg" alt="line" />
            More Works
          </h2>
          <Slider {...settings}>
            <div>
              <div className="sliderInner">
              <a href="/Memoria" className="text-decoration-none">
                <div className="imgHoverEffect position-relative">
                  <a
                    href="/Memoria"
                    className="colorWhite fw-800"
                    onMouseEnter={props.onMouseEnterSmall}
                    onMouseLeave={props.onMouseLeaveSmall}
                  >
                    View Projects &nbsp;
                    <img
                      src="/images/icons/topArrowWhite.svg"
                      alt="toparrow"
                      className="img-fluid"
                    />
                  </a>
                  <img src="/images/cards/Creative/memoria.jpg" alt="work" />
                </div>
                <h2 className="colorBlack fw-800">Memoria</h2>
                <span className="colorGrey fw-400">Creative Design</span>
                </a>
              </div>
            </div>
            <div>
              <div className="sliderInner">
              <a href="/rsbl-calendar" className="text-decoration-none">
                <div className="imgHoverEffect position-relative">
                  <a
                    href="/rsbl-calendar"
                    className="colorWhite fw-800"
                    onMouseEnter={props.onMouseEnterSmall}
                    onMouseLeave={props.onMouseLeaveSmall}
                  >
                    View Projects &nbsp;
                    <img
                      src="/images/icons/topArrowWhite.svg"
                      alt="toparrow"
                      className="img-fluid"
                    />
                  </a>
                  <img src="/images/cards/Creative/rsbl.jpg" alt="work" />
                </div>
                <h2 className="colorBlack fw-800">RSBL Calendar</h2>
                <span className="colorGrey fw-400">Creative Design</span>
                </a>
              </div>
            </div>
            <div>
              <div className="sliderInner">
              <a href="/aym-syntex-brochure" className="text-decoration-none">
                <div className="imgHoverEffect position-relative">
                  <a
                    href="/aym-syntex-brochure"
                    className="colorWhite fw-800"
                    onMouseEnter={props.onMouseEnterSmall}
                    onMouseLeave={props.onMouseLeaveSmall}
                  >
                    View Projects &nbsp;
                    <img
                      src="/images/icons/topArrowWhite.svg"
                      alt="toparrow"
                      className="img-fluid"
                    />
                  </a>
                  <img src="/images/cards/Creative/aymbro.jpg" alt="work" />
                </div>
                <h2 className="colorBlack fw-800">Aym Syntex Brochure</h2>
                <span className="colorGrey fw-400">Creative Design</span>
                </a>
              </div>
            </div>
            <div>
              <div className="sliderInner">
              <a href="/aym-syntex-annual-report" className="text-decoration-none">
                <div className="imgHoverEffect position-relative">
                  <a
                    href="/aym-syntex-annual-report"
                    className="colorWhite fw-800"
                    onMouseEnter={props.onMouseEnterSmall}
                    onMouseLeave={props.onMouseLeaveSmall}
                  >
                    View Projects &nbsp;
                    <img
                      src="/images/icons/topArrowWhite.svg"
                      alt="toparrow"
                      className="img-fluid"
                    />
                  </a>
                  <img src="/images/cards/Creative/acrt.jpg" alt="work" />
                </div>
                <h2 className="colorBlack fw-800">AYM Syntex Annul Report</h2>
                <span className="colorGrey fw-400">Creative Design</span>
                </a>
              </div>
            </div>
          </Slider>
          <Link
            to="/works"
            className="unsetAll"
            onClick={() => window.scrollTo(0, 0)}
            onMouseEnter={props.onMouseEnterSmall}
            onMouseLeave={props.onMouseLeaveSmall}
          >
            <span className="commonViewAll">View Our Projects</span>
            <Icon icon="clarity:arrow-line" />
          </Link>
        </div>
      </section>
    </>
  );
};

export default LjmLogo;

import React, { useEffect, useState } from "react";
import $ from "jquery";
import WOW from "wowjs";
import { Helmet } from "react-helmet-async";
import News from "../../Shared/News";
import { Icon } from "@iconify/react";

const WebsiteApp = (props) => {
  const [isACtive, setisACtive] = useState(0);

  const handleTabClick = (i) => {
    setisACtive(i);
  };

  useEffect(() => {
    const wow = new WOW.WOW({
      live: false,
    });
    wow.init();
  }, []);

  $(".projectSectiontabs li").on("click", function () {
    $(this).addClass("active").siblings().removeClass("active");
  });

  return (
    <>
    <Helmet>
        <title>Website Development Company in Mumbai, India | Reverse Thought</title>
        <meta name="description" content="As a mobile app and website development agency in Mumbai, we provide top services for CMS development, UI/ UX Design, and much more. Contact us!" />
        <meta name="robots" content="max-image-preview:large" />
        <meta name="google-site-verification" content="HoCjksaLBwM7qPH8Nt-Rzrqa5K02wXYiJwFSEMC4j8g" />
        <meta name="p:domain_verify" content="f307b6f44d53f755ef67d9a9a3666de7" />
        <meta name="keywords" content="ecommerce website design india,best website development company in india,top website development companies in india,web development company,web development company in india,website development in india,best web design company in mumbai,best web design firms,best website designers in india,top web designing companies in mumbai,top website designers in mumbai,web design agency in mumbai,web design company,web design company india,web design company mumbai,web designing company in mumbai,web development services mumbai,website development agency in india,website development service mumbai" />
        <link rel="canonical" href="https://www.reversethought.com/works/website-app" />
        <meta name="generator" content="All in One SEO (AIOSEO) 4.4.7.1" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:site_name" content="Creative Agency In Mumbai | Branding Agency India - Reverse Thought" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Website Development Company in Mumbai, India" />
        <meta property="og:description" content="We create immersive, intuitive and inspiring websites for desktops and mobiles. Some of our services include interactive E-commerce websites, CMS Development and UI/UX Design." />
        <meta property="og:url" content="https://www.reversethought.com/works/website-app" />
        <meta property="article:tag" content="ecommerce website design india" />
        <meta property="article:tag" content="best website development company in india" />
        <meta property="article:tag" content="top website development companies in india" />
        <meta property="article:tag" content="web development company" />
        <meta property="article:tag" content="web development company in india" />
        <meta property="article:tag" content="website development in india" />
        <meta property="article:tag" content="best web design company in mumbai" />
        <meta property="article:tag" content="best web design firms" />
        <meta property="article:tag" content="best website designers in india" />
        <meta property="article:tag" content="top web designing companies in mumbai" />
        <meta property="article:tag" content="top website designers in mumbai" />
        <meta property="article:tag" content="web design agency in mumbai" />
        <meta property="article:tag" content="web design company" />
        <meta property="article:tag" content="web design company india" />
        <meta property="article:tag" content="web design company mumbai" />
        <meta property="article:tag" content="web designing company in mumbai" />
        <meta property="article:tag" content="web development services mumbai" />
        <meta property="article:tag" content="website development agency in india" />
        <meta property="article:tag" content="website development service mumbai" />
        <meta property="article:published_time" content="2018-12-26T08:06:54+00:00" />
        <meta property="article:modified_time" content="2023-12-06T11:17:12+00:00" />
        <meta property="article:publisher" content="https://www.facebook.com/rtcspl/" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@rthoughtindia" />
        <meta name="twitter:title" content="Website Development Company in Mumbai, India" />
        <meta name="twitter:description" content="We create immersive, intuitive and inspiring websites for desktops and mobiles. Some of our services include interactive E-commerce websites, CMS Development and UI/UX Design." />
        <meta name="google" content="nositelinkssearchbox" />

      </Helmet>
      <section className="projectsGallery">
        <div className="content_container">
          <ul className="projectSectiontabs p-0 m-0 d-flex justify-content-between align-items-center wow animate__animated animate__fadeIn">
            <a href="/works/all-projects" className="text-decoration-none mx-2">
              {" "}
              <li
                className="colorLightGrey fw-800  lh-1"
                onClick={() => handleTabClick(0)}
              >
                All Projects
              </li>
            </a>
            <a href="/works/website-app" className="text-decoration-none mx-2">
              {" "}
              <li
                className="colorLightGrey fw-800 active lh-1"
                onClick={() => handleTabClick(1)}
              >
                Websites/App
              </li>
            </a>
            <a
              href="/works/video-production"
              className="text-decoration-none mx-2"
            >
              <li
                className="colorLightGrey fw-800 lh-1"
                onClick={() => handleTabClick(2)}
              >
                Video Production
              </li>
            </a>
            <a
              href="/works/digital-marketing"
              className="text-decoration-none mx-2"
            >
              <li
                className="colorLightGrey fw-800 lh-1"
                onClick={() => handleTabClick(3)}
              >
                Digital Marketing
              </li>
            </a>
            <a
              href="/works/creative-design"
              className="text-decoration-none mx-2"
            >
              {" "}
              <li
                className="colorLightGrey fw-800 lh-1"
                onClick={() => handleTabClick(4)}
              >
                Creative Design
              </li>
            </a>
            <a
              href="/works/digital-activation"
              className="text-decoration-none mx-2"
            >
              {" "}
              <li 
                className="colorLightGrey fw-800 lh-1"
                onClick={() => handleTabClick(5)}
              >
                Digital Activations
              </li>
            </a>
            <a href="/works/photography" className="text-decoration-none mx-2">
              {" "}
              <li
                className="colorLightGrey fw-800 lh-1"
                onClick={() => handleTabClick(6)}
              >
                Photography
              </li>
            </a>
          </ul>

          <div className="projectGalleryMain">
              <div className="projectsGalleryL">
                <a
                  href="/elleyselectrics"
                  onMouseEnter={props.onMouseEnter}
                  onMouseLeave={props.onMouseLeave}
                >
                  <div className="project_box wow animate__animated animate__fadeInUp">
                    <img
                      src="/images/projects/all-projects/proj-1.png"
                      alt="Project"
                      className="img-fluid w-100"
                    />
                    <div className="project_overview">
                      <div className="top_overview">
                        <h2 className="proj_name text-black">
                          Elleys’ <br />
                          Electronic
                        </h2>
                        <p className="proj_type text-black">Web Development</p>
                      </div>
                      <div className="bottom_overview">
                        <a
                          href="/elleyselectrics"
                          className="unsetAll"
                          onClick={() => window.scrollTo(0, 0)}
                        >
                          <span className="commonViewAll">View Project</span>
                          <Icon icon="clarity:arrow-line" />
                        </a>
                      </div>
                    </div>
                  </div>
                </a>
                <a
                  href="/aym-syntex"
                  onMouseEnter={props.onMouseEnter}
                  onMouseLeave={props.onMouseLeave}
                >
                  <div className="project_box wow animate__animated animate__fadeInUp">
                    <img
                      src="/images/thumbnails/all_projects/aymwebsite.jpg"
                      alt="Project"
                      className="img-fluid w-100"
                    />
                    <div className="project_overview">
                      <div className="top_overview">
                        <h2 className="proj_name text-black">
                          AYM Syntex <br />
                        </h2>
                        <p className="proj_type text-black">Web Development</p>
                      </div>
                      <div className="bottom_overview">
                        <a
                          href="/aym-syntex"
                          className="unsetAll"
                          onClick={() => window.scrollTo(0, 0)}
                        >
                          <span className="commonViewAll">View Project</span>
                          <Icon icon="clarity:arrow-line" />
                        </a>
                      </div>
                    </div>
                  </div>
                </a>
                <a
                  href="/malik-architecture"
                  onMouseEnter={props.onMouseEnter}
                  onMouseLeave={props.onMouseLeave}
                >
                  <div className="project_box wow animate__animated animate__fadeInUp">
                    <img
                      src="/images/thumbnails/all_projects/malik.webp"
                      alt="Project"
                      className="img-fluid w-100"
                    />
                    <div className="project_overview">
                      <div className="top_overview">
                        <h2 className="proj_name text-black">
                          Malik Architecture
                          <br />
                        </h2>
                        <p className="proj_type text-black">Web Development</p>
                      </div>
                      <div className="bottom_overview">
                        <a
                          href="/malik-architecture"
                          className="unsetAll"
                          onClick={() => window.scrollTo(0, 0)}
                        >
                          <span className="commonViewAll">View Project</span>
                          <Icon icon="clarity:arrow-line" />
                        </a>
                      </div>
                    </div>
                  </div>
                </a>
                
              </div>

              <div className="projectsGalleryM">
                <a
                  href="/nagarkot-logistics"
                  onMouseEnter={props.onMouseEnter}
                  onMouseLeave={props.onMouseLeave}
                >
                  <div className="project_box wow animate__animated animate__fadeInUp">
                    <img
                      src="/images/thumbnails/all_projects/ship.webp"
                      alt="Project"
                      className="img-fluid w-100"
                    />
                    <div className="project_overview">
                      <div className="top_overview">
                        <h2 className="proj_name text-black">
                          Nagarkot
                          <br />
                          Logistics
                        </h2>
                        <p className="proj_type text-black">Web Development </p>
                      </div>
                      <div className="bottom_overview">
                        <a
                          href="/nagarkot-logistics"
                          className="unsetAll"
                          onClick={() => window.scrollTo(0, 0)}
                        >
                          <span className="commonViewAll">View Project</span>
                          <Icon icon="clarity:arrow-line" />
                        </a>
                      </div>
                    </div>
                  </div>
                </a>
                <a
                  href="/notandas"
                  onMouseEnter={props.onMouseEnter}
                  onMouseLeave={props.onMouseLeave}
                >
                  <div className="project_box wow animate__animated animate__fadeInUp">
                    <img
                      src="/images/projects/all-projects/proj-6.png"
                      alt="Project"
                      className="img-fluid w-100"
                    />
                    <div className="project_overview">
                      <div className="top_overview">
                        <h2 className="proj_name text-black">
                          Notandas <br />
                          Jewellery
                        </h2>
                        <p className="proj_type text-black">Web Development</p>
                      </div>
                      <div className="bottom_overview">
                        <a
                          href="/notandas"
                          className="unsetAll"
                          onClick={() => window.scrollTo(0, 0)}
                        >
                          <span className="commonViewAll">View Project</span>
                          <Icon icon="clarity:arrow-line" />
                        </a>
                      </div>
                    </div>
                  </div>
                </a>
                <a
                  href="/vardhman"
                  onMouseEnter={props.onMouseEnter}
                  onMouseLeave={props.onMouseLeave}
                >
                  <div className="project_box wow animate__animated animate__fadeInUp">
                    <img
                      src="/images/projects/all-projects/proj-7.png"
                      alt="Project"
                      className="img-fluid w-100"
                    />
                    <div className="project_overview">
                      <div className="top_overview">
                        <h2 className="proj_name text-black">Vardhman</h2>
                        <p className="proj_type text-black">Web Development</p>
                      </div>
                      <div className="bottom_overview">
                        <a
                          href="/vardhman"
                          className="unsetAll"
                          onClick={() => window.scrollTo(0, 0)}
                        >
                          <span className="commonViewAll">View Project</span>
                          <Icon icon="clarity:arrow-line" />
                        </a>
                      </div>
                    </div>
                  </div>
                </a>
                <a
                  href="/goodricke"
                  onMouseEnter={props.onMouseEnter}
                  onMouseLeave={props.onMouseLeave}
                >
                  <div className="project_box wow animate__animated animate__fadeInUp">
                    <img
                      src="/images/thumbnails/all_projects/gdricke.jpg"
                      alt="Project"
                      className="img-fluid w-100"
                    />
                    <div className="project_overview">
                      <div className="top_overview">
                        <h2 className="proj_name text-black">Goodricke</h2>
                        <p className="proj_type text-black">Web Development</p>
                      </div>
                      <div className="bottom_overview">
                        <a
                          href="/goodricke"
                          className="unsetAll"
                          onClick={() => window.scrollTo(0, 0)}
                        >
                          <span className="commonViewAll">View Project</span>
                          <Icon icon="clarity:arrow-line" />
                        </a>
                      </div>
                    </div>
                  </div>
                </a>
                <a
                  href="/Bizdesign"
                  onMouseEnter={props.onMouseEnter}
                  onMouseLeave={props.onMouseLeave}
                >
                  <div className="project_box wow animate__animated animate__fadeInUp">
                    <img
                      src="/images/thumbnails/all_projects/i2m.webp"
                      alt="Project"
                      className="img-fluid w-100"
                    />
                    <div className="project_overview">
                      <div className="top_overview">
                        <h2 className="proj_name text-black">Bizdesign Lab</h2>
                        <p className="proj_type text-black">Web Development</p>
                      </div>
                      <div className="bottom_overview">
                        <a
                          href="/bizdesign"
                          className="unsetAll"
                          onClick={() => window.scrollTo(0, 0)}
                        >
                          <span className="commonViewAll">View Project</span>
                          <Icon icon="clarity:arrow-line" />
                        </a>
                      </div>
                    </div>
                  </div>
                </a>
              </div>

              <div className="projectsGalleryR">
                <a
                  href="/welspun"
                  onMouseEnter={props.onMouseEnter}
                  onMouseLeave={props.onMouseLeave}
                >
                  <div className="project_box wow animate__animated animate__fadeInUp">
                    <img
                      src="/images/thumbnails/all_projects/welp.png"
                      alt="Project"
                      className="img-fluid w-100"
                    />
                    <div className="project_overview">
                      <div className="top_overview">
                        <h2 className="proj_name text-black">Welspun </h2>
                        <p className="proj_type text-black">Web Development</p>
                      </div>
                      <div className="bottom_overview">
                        <a
                          href="/welspun"
                          className="unsetAll"
                          onClick={() => window.scrollTo(0, 0)}
                        >
                          <span className="commonViewAll">View Project</span>
                          <Icon icon="clarity:arrow-line" />
                        </a>
                      </div>
                    </div>
                  </div>
                </a>
                <a
                  href="/continuum-india"
                  onMouseEnter={props.onMouseEnter}
                  onMouseLeave={props.onMouseLeave}
                >
                  <div className="project_box wow animate__animated animate__fadeInUp">
                    <img
                      src="/images/thumbnails/all_projects/conindia.webp"
                      alt="Project"
                      className="img-fluid w-100"
                    />
                    <div className="project_overview">
                      <div className="top_overview">
                        <h2 className="proj_name text-black">
                          Continuum India
                        </h2>
                        <p className="proj_type text-black">Web Development</p>
                      </div>
                      <div className="bottom_overview">
                        <a
                          href="/continuum-india"
                          className="unsetAll"
                          onClick={() => window.scrollTo(0, 0)}
                        >
                          <span className="commonViewAll">View Project</span>
                          <Icon icon="clarity:arrow-line" />
                        </a>
                      </div>
                    </div>
                  </div>
                </a>
                <a
                  href="/local2030"
                  onMouseEnter={props.onMouseEnter}
                  onMouseLeave={props.onMouseLeave}
                >
                  <div className="project_box wow animate__animated animate__fadeInUp">
                    <img
                      src="/images/thumbnails/all_projects/webm.webp"
                      alt="Project"
                      className="img-fluid w-100"
                    />
                    <div className="project_overview">
                      <div className="top_overview">
                        <h2 className="proj_name text-black">Local 2030</h2>
                        <p className="proj_type text-black">Web Development</p>
                      </div>
                      <div className="bottom_overview">
                        <a
                          href="/local2030"
                          className="unsetAll"
                          onClick={() => window.scrollTo(0, 0)}
                        >
                          <span className="commonViewAll">View Project</span>
                          <Icon icon="clarity:arrow-line" />
                        </a>
                      </div>
                    </div>
                  </div>
                </a>
                <a
                  href="/texprocil"
                  onMouseEnter={props.onMouseEnter}
                  onMouseLeave={props.onMouseLeave}
                >
                  <div className="project_box wow animate__animated animate__fadeInUp">
                    <img
                      src="/images/thumbnails/all_projects/mc.webp"
                      alt="Project"
                      className="img-fluid w-100"
                    />
                    <div className="project_overview">
                      <div className="top_overview">
                        <h2 className="proj_name text-black">Texprocil</h2>
                        <p className="proj_type text-black">Web Development</p>
                      </div>
                      <div className="bottom_overview">
                        <a
                          href="/texprocil"
                          className="unsetAll"
                          onClick={() => window.scrollTo(0, 0)}
                        >
                          <span className="commonViewAll">View Project</span>
                          <Icon icon="clarity:arrow-line" />
                        </a>
                      </div>
                    </div>
                  </div>
                </a>
                <a
                  href="/256grays"
                  onMouseEnter={props.onMouseEnter}
                  onMouseLeave={props.onMouseLeave}
                >
                  <div className="project_box wow animate__animated animate__fadeInUp">
                    <img
                      src="/images/thumbnails/all_projects/gray2333.webp"
                      alt="Project"
                      className="img-fluid w-100"
                    />
                    <div className="project_overview">
                      <div className="top_overview">
                        <h2 className="proj_name text-black">256 Grays</h2>
                        <p className="proj_type text-black">Web Development</p>
                      </div>
                      <div className="bottom_overview">
                        <a
                          href="/256grays"
                          className="unsetAll"
                          onClick={() => window.scrollTo(0, 0)}
                        >
                          <span className="commonViewAll">View Project</span>
                          <Icon icon="clarity:arrow-line" />
                        </a>
                      </div>
                    </div>
                  </div>
                </a>
              </div>              
          </div>
          <div className="projectGalleryMain">
            <div className="projectsGalleryL">
            <a
                href="/dodhiaweb"
                onMouseEnter={props.onMouseEnter}
                onMouseLeave={props.onMouseLeave}
              >
                <div className="project_box wow animate__animated animate__fadeInUp">
                  <img
                    src="/images/thumbnails/all_projects/dodhiawebsite.png"
                    alt="Project"
                    className="img-fluid w-100"
                  />
                  <div className="project_overview">
                    <div className="top_overview">
                      <h2 className="proj_name text-black">Dodhia Group</h2>
                      <p className="proj_type text-black">Web Development</p>
                    </div>
                    <div className="bottom_overview">
                      <a
                        href="/dodhiaweb"
                        className="unsetAll"
                        onClick={() => window.scrollTo(0, 0)}
                      >
                        <span className="commonViewAll">View Project</span>
                        <Icon icon="clarity:arrow-line" />
                      </a>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <div className="projectsGalleryM"></div>
            <div className="projectsGalleryR"></div>
          </div>
        </div>
      </section>
      <section className="weareintheNews customPaddingClass">
        <div className="content_container">
          <News />
        </div>
      </section>
    </>
  );
};

export default WebsiteApp;

import React, { useEffect, useState } from "react";
import $ from "jquery";
import WOW from "wowjs";
import News from "../../Shared/News";
// import News from "../Shared/News";
import { Icon } from "@iconify/react";
import { Helmet } from "react-helmet-async";
const DigitalActivation = (props) => {
  const [isACtive, setisACtive] = useState(0);

  const handleTabClick = (i) => {
    setisACtive(i);
  };

  useEffect(() => {
    const wow = new WOW.WOW({
      live: false,
    });
    wow.init();
  }, []);

  $(".projectSectiontabs li").on("click", function () {
    $(this).addClass("active").siblings().removeClass("active");
  });

  // const ourWorkLeft = [
  //   {
  //     img: "proj-1.png",
  //     projectName: `Elley's <br/> Electronics`,
  //     projectType: "Web Development",
  //     projectLink: "https://www.elleys.group/",
  //   },
  //   {
  //     img: "proj-2.png",
  //     projectName: `Goodricke`,
  //     projectType: "Web Development",
  //     projectLink: "https://www.goodricke.com/",
  //   },
  //   {
  //     img: "proj-3.png",
  //     projectName: `AYM Syntex`,
  //     projectType: "Web Development",
  //     projectLink: "https://www.aymsyntex.com/",
  //   },
  //   {
  //     img: "proj-4.png",
  //     projectName: "Induslnd Bank",
  //     projectType: "",
  //     projectLink: "",
  //   },
  //   {
  //     img: "proj-13.png",
  //     projectName: "",
  //     projectType: "",
  //     projectLink: "",
  //   },
  //   {
  //     img: "proj-14.png",
  //     projectName: "",
  //     projectType: "",
  //     projectLink: "",
  //   },
  //   {
  //     img: "proj-15.png",
  //     projectName: "",
  //     projectType: "",
  //     projectLink: "",
  //   },
  //   {
  //     img: "proj-16.png",
  //     projectName: "",
  //     projectType: "",
  //     projectLink: "",
  //   },
  //   {
  //     img: "proj-17.png",
  //     projectName: "",
  //     projectType: "",
  //     projectLink: "",
  //   },
  //   {
  //     img: "proj-18.png",
  //     projectName: "",
  //     projectType: "",
  //     projectLink: "",
  //   },
  // ];

  return (
    <>
<Helmet>
  <title>Digital Activation Services | Reversethought</title>
  <meta name="description" content="Reverse Thought is the best  digital marketing and advertising  agency in Mumbai, India specializes in web UI/UX design, Apps, Videos, and much more. " />
  <meta name="robots" content="max-image-preview:large" />
  <link rel="canonical" href="https://www.reversethought.com/works/digital-activation" />
  <meta property="og:title" content="Digital Activation | Reversethought" />
  <meta property="og:description" content="Reverse Thought is the best  digital marketing and advertising  agency in Mumbai, India specializes in web UI/UX design, Apps, Videos, and much more"/>
  <meta property="og:url" content="https://reversethought.com/works/digital-activation" />
  <meta name="twitter:title" content="Digital Activation | Reversethought" />
  <meta name="twitter:description" content="Reverse Thought is the best  digital marketing and advertising  agency in Mumbai, India specializes in web UI/UX design, Apps, Videos, and much more" />
    

  <meta name="google-site-verification" content="HoCjksaLBwM7qPH8Nt-Rzrqa5K02wXYiJwFSEMC4j8g" />
  <meta name="p:domain_verify" content="f307b6f44d53f755ef67d9a9a3666de7" />
  <meta name="keywords" content="top social media marketing agencies in mumbai,best digital marketing company in mumbai,online lead generation companies,top creative agency india,creative agencies in mumbai,top digital agency in mumbai 2022,digital creative agency mumbai,digital creative agency india,digital agency mumbai,creative agency in mumbai,creative agency in india,design agency in mumbai" />        
  <meta name="generator" content="All in One SEO (AIOSEO) 4.4.7.1" />
  <meta property="og:site_name" content="Creative Agency In Mumbai | Branding Agency India - Reverse Thought" />
</Helmet>
      <section className="projectsGallery">
        <div className="content_container">
          <ul className="projectSectiontabs p-0 m-0 d-flex justify-content-between align-items-center wow animate__animated animate__fadeIn">
            <a href="/works/all-projects" className="text-decoration-none mx-2">
              {" "}
              <li className="colorLightGrey fw-800 lh-1 ">All Projects</li>
            </a>
            <a href="/works/website-app" className="text-decoration-none mx-2">
              {" "}
              <li className="colorLightGrey fw-800 lh-1">Websites/App</li>
            </a>
            <a
              href="/works/video-production"
              className="text-decoration-none mx-2"
            >
              <li className="colorLightGrey fw-800 lh-1">Video Production</li>
            </a>
            <a
              href="/works/digital-marketing"
              className="text-decoration-none mx-2"
            >
              <li className="colorLightGrey fw-800 lh-1">Digital Marketing</li>
            </a>
            <a
              href="/works/creative-design"
              className="text-decoration-none mx-2"
            >
              {" "}
              <li className="colorLightGrey fw-800 lh-1">Creative Design</li>
            </a>
            <a
              href="/works/digital-activation"
              className="text-decoration-none mx-2"
            >
              {" "}
              <li className="colorLightGrey fw-800 lh-1 active">
                Digital Activations
              </li>
            </a>
            <a href="/works/photography" className="text-decoration-none mx-2">
              {" "}
              <li className="colorLightGrey fw-800 lh-1">Photography</li>
            </a>
          </ul>

          <div className="projectGalleryMain">
            <div className="projectsGalleryL">
              <a
                href="/alkem-ar-filters"
                onMouseEnter={props.onMouseEnter}
                onMouseLeave={props.onMouseLeave}
              >
                <div className="project_box wow animate__animated animate__fadeInUp">
                  <img
                    src="/images/thumbnails/all_projects/score.webp"
                    alt="Project"
                    className="img-fluid w-100"
                  />
                  <div className="project_overview">
                    <div className="top_overview">
                      <h2 className="proj_name text-black">
                        Alkem AR <br />
                        Filters
                      </h2>
                      <p className="proj_type text-black">Digital Activation</p>
                    </div>
                    <div className="bottom_overview">
                      <a
                        href="/alkem-ar-filters"
                        className="unsetAll"
                        onClick={() => window.scrollTo(0, 0)}
                      >
                        <span className="commonViewAll">View Project</span>
                        <Icon icon="clarity:arrow-line" />
                      </a>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div className="projectsGalleryM">
              <a
                href="/mcdowell"
                onMouseEnter={props.onMouseEnter}
                onMouseLeave={props.onMouseLeave}
              >
                <div className="project_box wow animate__animated animate__fadeInUp">
                  <img
                    src="/images/thumbnails/all_projects/mcd.jpg"
                    alt="Project"
                    className="img-fluid w-100"
                  />
                  <div className="project_overview">
                    <div className="top_overview">
                      <h2 className="proj_name text-black">McDowells No.1</h2>
                      <p className="proj_type text-black">Digital Activation</p>
                    </div>
                    <div className="bottom_overview">
                      <a
                        href="/mcdowell"
                        className="unsetAll"
                        onClick={() => window.scrollTo(0, 0)}
                      >
                        <span className="commonViewAll">View Project</span>
                        <Icon icon="clarity:arrow-line" />
                      </a>
                    </div>
                  </div>
                </div>
              </a>
            </div>

            <div className="projectsGalleryR">
              <a
                href="/loreal-matrix"
                onMouseEnter={props.onMouseEnter}
                onMouseLeave={props.onMouseLeave}
              >
                <div className="project_box wow animate__animated animate__fadeInUp">
                  <img
                    src="/images/thumbnails/all_projects/loreal.jpg"
                    alt="Project"
                    className="img-fluid w-100"
                  />
                  <div className="project_overview">
                    <div className="top_overview">
                      <h2 className="proj_name text-black">Lo'real Matrix </h2>
                      <p className="proj_type text-black">Digital Activation</p>
                    </div>
                    <div className="bottom_overview">
                      <a
                        href="/loreal-matrix"
                        className="unsetAll"
                        onClick={() => window.scrollTo(0, 0)}
                      >
                        <span className="commonViewAll">View Project</span>
                        <Icon icon="clarity:arrow-line" />
                      </a>
                    </div>
                  </div>
                </div>
              </a>
              <a
                href="/amazon-sambhav"
                onMouseEnter={props.onMouseEnter}
                onMouseLeave={props.onMouseLeave}
              >
                <div className="project_box wow animate__animated animate__fadeInUp">
                  <img
                    src="/images/thumbnails/all_projects/amazon.jpg"
                    alt="Project"
                    className="img-fluid w-100"
                  />
                  <div className="project_overview">
                    <div className="top_overview">
                      <h2 className="proj_name text-black">Amazon Sambhav</h2>
                      <p className="proj_type text-black">Digital Activation</p>
                    </div>
                    <div className="bottom_overview">
                      <a
                        href="/amazon-sambhav"
                        className="unsetAll"
                        onClick={() => window.scrollTo(0, 0)}
                      >
                        <span className="commonViewAll">View Project</span>
                        <Icon icon="clarity:arrow-line" />
                      </a>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="weareintheNews customPaddingClass">
        <div className="content_container">
          <News />
        </div>
      </section>
    </>
  );
};

export default DigitalActivation;

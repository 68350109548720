import React from "react";
import { Helmet } from "react-helmet-async";


const Sitemap = () => {
  return (
    <>
  <Helmet>
  <title>Sitemap | Reversethought</title>
  {/* <meta name="description" content="Learn how Reverse Thought's privacy policy ensures the protection of your valuable data. Discover our commitment to safeguarding your privacy and maintaining transparency." />                           */}
  <link rel="canonical" href="https://www.reversethought.com/sitemap"/>
  <meta property="og:title" content="Sitemap | Reversethought" />
  {/* <meta property="og:description" content="Learn how Reverse Thought's privacy policy ensures the protection of your valuable data. Discover our commitment to safeguarding your privacy and maintaining transparency."/> */}
  <meta property="og:url" content="https://www.reversethought.com/sitemap" />
  <meta name="twitter:title" content="Sitemap | Reversethought" />
  {/* <meta name="twitter:description" content="Learn how Reverse Thought's privacy policy ensures the protection of your valuable data. Discover our commitment to safeguarding your privacy and maintaining transparency." /> */}
    

  <meta name="google-site-verification" content="HoCjksaLBwM7qPH8Nt-Rzrqa5K02wXYiJwFSEMC4j8g" />
  <meta name="p:domain_verify" content="f307b6f44d53f755ef67d9a9a3666de7" />
  <meta name="keywords" content="top social media marketing agencies in mumbai,best digital marketing company in mumbai,online lead generation companies,top creative agency india,creative agencies in mumbai,top digital agency in mumbai 2022,digital creative agency mumbai,digital creative agency india,digital agency mumbai,creative agency in mumbai,creative agency in india,design agency in mumbai" />        
  <meta name="generator" content="All in One SEO (AIOSEO) 4.4.7.1" />
  <meta property="og:site_name" content="Creative Agency In Mumbai | Branding Agency India - Reverse Thought" />
</Helmet>


      <section
        className="sitemap py-5"
        style={{
          padding: "90px 0 41px;",
          marginLeft: "-15px",
          marginRight: "-15px",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-xs-12">
              <ul>
                <li className="my-2" style={{ listStyle: "circle" }}>
                  <a
                    href="/services"
                    className="text-decoration-none text-dark"
                  >
                    Services
                  </a>
                  <ul>
                    <li className="my-2" style={{ listStyle: "disc" }}>
                      <a
                        href="/website-and-mobile/"
                        className="text-decoration-none text-dark"
                      >
                        Website/App
                      </a>
                    </li>
                    <li className="my-2" style={{ listStyle: "disc" }}>
                      <a
                        href="/video-production/"
                        className="text-decoration-none text-dark"
                      >
                        Video Production
                      </a>
                    </li>
                    <li className="my-2" style={{ listStyle: "disc" }}>
                      <a
                        href="/digital-marketing/"
                        className="text-decoration-none text-dark"
                      >
                        Digital Marketing
                      </a>
                    </li>
                    <li className="my-2" style={{ listStyle: "disc" }}>
                      <a
                        href="/Creative-Services"
                        className="text-decoration-none text-dark"
                      >
                        Creative Design
                      </a>
                    </li>
                    <li className="my-2" style={{ listStyle: "disc" }}>
                      <a
                        href="/Digital-activation-Services"
                        className="text-decoration-none text-dark"
                      >
                        Digital Activations
                      </a>
                    </li>
                    <li className="my-2" style={{ listStyle: "disc" }}>
                      <a
                        href="/still-photography/"
                        className="text-decoration-none text-dark"
                      >
                        Photography
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="my-2" style={{ listStyle: "circle" }}>
                  <a
                    href="/works"
                    className="text-decoration-none text-dark"
                  >
                    Our Work
                  </a>
                </li>
                <li className="my-2" style={{ listStyle: "circle" }}>
                  <a href="/clients" className="text-decoration-none text-dark">
                    Clients
                  </a>
                </li>
                <li className="my-2" style={{ listStyle: "circle" }}>
                  <a
                    href="/about-us"
                    className="text-decoration-none text-dark"
                  >
                    About Us
                  </a>
                </li>
                <li className="my-2" style={{ listStyle: "circle" }}>
                  <a
                    href="/contact-us"
                    className="text-decoration-none text-dark"
                  >
                    Contact Us
                  </a>
                </li>
                <li className="my-2" style={{ listStyle: "circle" }}>
                  <a href="/careers" className="text-decoration-none text-dark">
                    Careers
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Sitemap;

import React, { useEffect } from "react";
import Slider from "react-slick";
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
import WOW from "wowjs";
import { Helmet } from "react-helmet-async";

const DodhiaCorporateFilm = (props) => {
  useEffect(() => {
    const wow = new WOW.WOW({
      live: false,
    });
    wow.init();
  }, []);

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
<Helmet>
<title>Dodhia Corporate Film | Reversethought</title>
<meta name="description" content="We are a Video Production agency in Mumbai, India | Explainer video production company for brands, commercial advertisements, corporate video makers, and more." />
        <meta name="robots" content="max-image-preview:large" />
        <meta name="google-site-verification" content="HoCjksaLBwM7qPH8Nt-Rzrqa5K02wXYiJwFSEMC4j8g" />
        <meta name="p:domain_verify" content="f307b6f44d53f755ef67d9a9a3666de7" />
        <meta name="keywords" content="best film production companies in mumbai,corporate video makers in mumbai,corporate video mumbai,corporate video production in mumbai,film production companies in mumbai,video production company in mumbai,video production mumbai,video production services in india,video production services in mumbai,animated video production,corporate video production company,corporate video production mumbai,explainer video production,video production company,corporate video production india" />
<link rel="canonical" href="https://www.reversethought.com/dodhia-corporate-film" />
        <meta name="generator" content="All in One SEO (AIOSEO) 4.4.7.1" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:site_name" content="Creative Agency In Mumbai | Branding Agency India - Reverse Thought" />
        <meta property="og:type" content="article" />
<meta property="og:title" content="Dodhia Corporate Film | Reversethought" />
<meta property="og:description" content="We create film &amp; corporate videos that instantly engages with the audience. We also create explainer videos, case study videos, 2D &amp; 3D Animated videos that grabs attention quickly." />
<meta property="og:url" content="https://www.reversethought.com/dodhia-corporate-film" />
        <meta property="article:tag" content="film production companies in mumbai" />
        <meta property="article:tag" content="video production company in mumbai" />
        <meta property="article:tag" content="video production mumbai" />
        <meta property="article:tag" content="video production services in india" />
        <meta property="article:tag" content="video production services in mumbai" />
        <meta property="article:tag" content="animated video production" />
        <meta property="article:tag" content="corporate video production company" />
        <meta property="article:tag" content="corporate video production mumbai" />
        <meta property="article:tag" content="explainer video production" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@rthoughtindia" />
<meta name="twitter:title" content="Dodhia Corporate Film | Reversethought" />
<meta name="twitter:description" content="We create film &amp; corporate videos that instantly engages with the audience. We also create explainer videos, case study videos, 2D &amp; 3D Animated videos that grabs attention quickly." />
        <meta name="google" content="nositelinkssearchbox" />
      </Helmet>

      <section className="ourWork">
        <div className="content_container">
          <h1 className="projectHeading colorBlack fw-800 text-center wow animate__animated animate__fadeIn">
            Dodhia Corporate Film
          </h1>
          <a
            href="https://www.youtube.com/watch?v=doKgi0eHV6w&list=PLxUEiGuaWBw9Ny5EajTgz5z0zdVt1YgCb" target="_blank"
            onMouseEnter={props.onMouseEnter}
            onMouseLeave={props.onMouseLeave}
          >
            <div className="position-relative wow animate__animated animate__fadeIn">
              <div className="playButton">
                <Icon icon="ph:play-fill" />
              </div>
              <img
                src="/images/works/videography/dodhiacorp/image243.jpg"
                alt="Dodhia Corp"
                className="img-fluid w-100"
              />
            </div>
          </a>
          <div className="gridSystem">
            <div className="gridSystemInner">
              <h2 className="colorRed fw-800 wow animate__animated animate__fadeIn">
                Brief
              </h2>
              <p className="colorGrey fw-400 wow animate__animated animate__fadeInLeft">
              An inspiring organisation that has witnessed an astronomical rise in the span of a Decade, Dodhia is a company to watch out for. Their unwavering focus on innovation and excellence and the values that drive them are effectively showcased in this energetic, fast-paced film. 
              </p>
              <div className="row m-0 briefDetails wow animate__animated animate__fadeIn">
                <div className="col-sm-6 p-0 briefDetailsInner wow animate__animated animate__fadeInLeft">
                  <h4 className="colorBlack fw-800">Client</h4>
                  <span className="colorGrey fw-400">
                    Dodhia Corporate Film
                  </span>
                </div>
                <div className="col-sm-6 p-0 briefDetailsInner wow animate__animated animate__fadeInLeft">
                  <h4 className="colorBlack fw-800">Industry</h4>
                  <span className="colorGrey fw-400">Corporate</span>
                </div>
                <div className="col-sm-6 p-0 briefDetailsInner wow animate__animated animate__fadeInRight">
                  <h4 className="colorBlack fw-800">Work</h4>
                  <span className="colorGrey fw-400">
                    Video Production & Still Photography
                  </span>
                </div>
                <div className="col-sm-6 p-0 briefDetailsInner wow animate__animated animate__fadeInRight">
                  <h4 className="colorBlack fw-800">Technology</h4>
                  <span className="colorGrey fw-400">Sony Camera</span>
                </div>
              </div>
              {/* <Link
                to="https://www.youtube.com/watch?v=doKgi0eHV6w&list=PLxUEiGuaWBw9Ny5EajTgz5z0zdVt1YgCb"
                className="unsetAll"
                target="_blank"
                onMouseEnter={props.onMouseEnterSmall}
                onMouseLeave={props.onMouseLeaveSmall}
              >
                <span className="commonViewAll">Check Videos</span>
                <Icon icon="clarity:arrow-line" />
              </Link> */}
            </div>
            <div className="gridSystemInner">
              <div className="row m-0 imageView">
                <div className="col-12 p-0 text-start imageViewL wow animate__animated animate__fadeInRight">
                  <img
                    src="/images/works/videography/dodhiacorp/image245.jpg"
                    alt="Dodhia Corp"
                    className="img-fluid w-100"
                  />
                </div>
                <div className="col-12 p-0 text-end imageViewR wow animate__animated animate__fadeInRight">
                  <img
                    src="/images/works/videography/dodhiacorp/image251.jpg"
                    alt="Dodhia Corp"
                    className="img-fluid w-100"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="serviceImageSection"
        onMouseEnter={props.mouseEnterOnBlack}
        onMouseLeave={props.mouseLeaveOnBlack}
      >
        <div className="content_container">
          <div className="row m-0 zigZagImageSection">
            <div className="col-12 p-0 zigZagImageSection1 wow animate__animated animate__fadeInRight">
              <img
                src="/images/works/videography/dodhiacorp/image254.jpg"
                alt="Dodhia Corp"
                className="img-fluid w-100"
              />
            </div>
            <div className="col-12 p-0 text-end zigZagImageSection2 wow animate__animated animate__fadeInLeft">
              <img
                src="/images/works/videography/dodhiacorp/image255.jpg"
                alt="Dodhia Corp"
                className="img-fluid w-100"
              />
            </div>
            <div className="col-12 p-0 zigZagImageSection3 wow animate__animated animate__fadeInRight">
              <img
                src="/images/works/videography/dodhiacorp/image252.jpg"
                alt="Dodhia Corp"
                className="img-fluid w-100"
              />
            </div>
            <div className="col-12 p-0 text-end zigZagImageSection4 wow animate__animated animate__fadeInLeft">
              <img
                src="/images/works/videography/dodhiacorp/image253.jpg"
                alt="Dodhia Corp"
                className="img-fluid w-100"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="ourWorkSlider wow animate__animated animate__slideInLeft">
        <div className="content_container">
          <h2 className="sectionHead colorBlack wow fw-800">
            <img src="/images/icons/line.svg" alt="line" />
            More Works
          </h2>
          <Slider {...settings}>
            <div>
              <div className="sliderInner">
                 <a href="/dodhia-textiles" className="text-decoration-none">
                <div className="imgHoverEffect position-relative">
                  <a
                    href="/dodhia-textiles"
                    className="colorWhite fw-800"
                    onMouseEnter={props.onMouseEnterSmall}
                    onMouseLeave={props.onMouseLeaveSmall}
                  >
                    View Projects &nbsp;
                    <img
                      src="/images/icons/topArrowWhite.svg"
                      alt="toparrow"
                      className="img-fluid"
                    />
                  </a>
                  <img src="/images/cards/Video/aym.jpg" alt="work" />
                </div>
                <h2 className="colorBlack fw-800">Dodhia Textiles</h2>
                <span className="colorGrey fw-400">Video Production</span>
                </a>
              </div>
            </div>
            <div>
              <div className="sliderInner">
                 <a href="/vijay-sales" className="text-decoration-none">
                <div className="imgHoverEffect position-relative">
                  <a
                    href="/vijay-sales"
                    className="colorWhite fw-800"
                    onMouseEnter={props.onMouseEnterSmall}
                    onMouseLeave={props.onMouseLeaveSmall}
                  >
                    View Projects &nbsp;
                    <img
                      src="/images/icons/topArrowWhite.svg"
                      alt="toparrow"
                      className="img-fluid"
                    />
                  </a>
                  <img
                    src="/images/works/videography/dhcinternationteaser/msk2.jpg"
                    alt="work"
                  />
                </div>
                <h2 className="colorBlack fw-800">Vijay Sales</h2>
                <span className="colorGrey fw-400">Video Production</span>
                </a>
              </div>
            </div>
            <div>
              <div className="sliderInner">
                 <a href="/rockman-values-film" className="text-decoration-none">
                <div className="imgHoverEffect position-relative">
                  <a
                    href="/rockman-values-film"
                    className="colorWhite fw-800"
                    onMouseEnter={props.onMouseEnterSmall}
                    onMouseLeave={props.onMouseLeaveSmall}
                  >
                    View Projects &nbsp;
                    <img
                      src="/images/icons/topArrowWhite.svg"
                      alt="toparrow"
                      className="img-fluid"
                    />
                  </a>
                  <img src="/images/cards/Video/idea.jpg" alt="work" />
                </div>
                <h2 className="colorBlack fw-800">Rockman Value Film</h2>
                <span className="colorGrey fw-400">Video Production</span>
                </a>
              </div>
            </div>
          </Slider>

          <Link
            to="/works"
            className="unsetAll"
            onClick={() => window.scrollTo(0, 0)}
            onMouseEnter={props.onMouseEnterSmall}
            onMouseLeave={props.onMouseLeaveSmall}
          >
            <span className="commonViewAll">View Our Projects</span>
            <Icon icon="clarity:arrow-line" />
          </Link>
        </div>
      </section>
    </>
  );
};

export default DodhiaCorporateFilm;

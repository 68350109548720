import React, { useState, useRef } from "react";
import { Icon } from "@iconify/react";
import emailjs from '@emailjs/browser';
import Swal from 'sweetalert2'

import { ThreeCircles } from "react-loader-spinner";

const EnquiryForm = (props) => {
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const showForm = () => {
    setIsFormVisible(true);
  };

  const hideForm = () => {
    setIsFormVisible(false);
  };

  const formRef = useRef();
  
  const [form, setForm] = useState
  ({
    name: '',
    c_name: '',
    email:'',
    number:'',
    interestedIn:'',
  })
  const [loading, setLoading] = useState(false);

  const handleChange = (e) =>
  {
    const { name, value } = e.target;
    setForm({ ...form, [name]:value })
  }


  const handleSubmit = (e) => {
    e.preventDefault();
    if (form.name == '' || form.c_name == '' || form.email == '' || form.mobile == '' || selectedValue == '')
    {
      Swal.fire
      ({
      position: 'center',
      icon: 'warning',
      title: "No invisible ink allowed",
      text: "Fill in those fields!",
      width: 'auto',
      })

      return;
    }
    setLoading(true);
    emailjs.send(
    'service_gwjflj9',
    'template_0a26vm9',
    {
      name: form.name,
      c_name: form.c_name,
      email: form.email,
      mobile: form.mobile,
      interestedIn: selectedValue,
    },
    'Cry1bGm7ffcQiSyHr'
    )
    .then(() => 
    {
      setLoading(false);
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: "Thank you !",
        text: "We'll get back to you soon !",
        showConfirmButton: true,
        width: 'auto',
      })
      setSelectedValue('');
      setForm(
        {
          name: '',
          c_name: '',
          email:'',
          mobile:'',
          interestedIn:'',
          
        }
      )
    }, (error) =>
    {
      setLoading(false);
      console.log(error);

      Swal.fire({
        position: 'center',
        icon: 'error',
        title: "Uh-oh!",
        text: "A wild glitch appeared!",
        showCancelButton: true,
        width: 'auto',
      })
    }
    )
  }
  const [selectedValue, setSelectedValue] = useState('');
  const handleSelectChange = (e) => {
    setSelectedValue(e.target.value);
    //e.preventDefault();
   
  
    setIsFormVisible(false);
  };

  return (
    <>
      {/* {successMessage && (
        <div className="alert alert-success">{successMessage}</div>
      )}
      {errorMessage && <div className="alert alert-danger">{errorMessage}</div>} */}
      <button
        className="colorWhite fw-800 enquireNowButton"
        onClick={showForm}
        onMouseEnter={props.mouseEnterOnRed}
        onMouseLeave={props.mouseLeaveOnRed}
      >
        <Icon icon="ph:arrow-right-bold" />
        <span>Enquiry Form</span>
        <Icon icon="ph:arrow-left-bold" />
      </button>
      <div
        className="enquiryForm"
        onMouseEnter={props.mouseEnterOnRed}
        onMouseLeave={props.mouseLeaveOnRed}
      >
        <form
          className={isFormVisible ? "visible" : ""}
          ref={formRef} onSubmit={handleSubmit}
        >
          <div className="close_quote_form" onClick={hideForm}>
            <Icon icon="iconamoon:close-thin" />
          </div>
          {/* <button className="colorWhite fw-800 submitButton" type="submit">
            <Icon icon="ph:arrow-right-bold" />
            <span>Submit</span>
            <Icon icon="ph:arrow-left-bold" />
          </button> */}
          <p
            className="colorWhite fw-800"
            onMouseEnter={props.onMouseEnterSmall}
            onMouseLeave={props.onMouseLeaveSmall}
          >
            Enquiry form
          </p>
          <span
            className="colorWhite fw-800"
            onMouseEnter={props.onMouseEnterSmall}
            onMouseLeave={props.onMouseLeaveSmall}
          >
            Fill in the details below and will get back to you shortly.
          </span>
          <div>
          <input type="text" className="colorWhite fw-400" required
              name="name" placeholder="Your Name*"  // pattern="[a-zA-Z][a-zA-Z ]{1,}"
              value={form.name} onChange={handleChange} />

            <input type="text" name="c_name"
              className="colorWhite fw-400" placeholder="Company Name"
              value={form.c_name} onChange={handleChange} />

           <select className="colorWhite fw-400" name="interestedIn" required value={selectedValue}
              onChange={handleSelectChange} >
              <option value="" selected disabled hidden>
                I’m Interested in....*
              </option>
              <option className="options py-5" value="Web Design">  Web Design  </option>
              <option className="options py-5" value="Graphic Design">  Graphic Design  </option>
              <option className="options py-5" value="Video Production">  Video Production  </option>
              <option className="options py-5" value="Creative Design">  Creative Design  </option>
              <option className="options py-5" value="Digital Activations">  Digital Activations  </option>
              <option className="options py-5" value="Digital Marketing">  Digital Marketing  </option>
              <option className="options py-5" value=" Photography">  Photography  </option>
            </select>

            <input type="email" name="email" className="colorWhite fw-400"
              required placeholder="Your E-mail*" value={form.email} onChange={handleChange}  />

            <input type="number" className="colorWhite fw-400" required
              name="mobile" placeholder="Your Mobile*" value={form.mobile} onChange={handleChange} pattern="[789][0-9]{9}" />
         

          <button className="colorWhite fw-800 submitButton" type="submit">
            <Icon icon="ph:arrow-right-bold" />
           
            <span> {loading ? 'Sending...' : 'Submit'}</span>
            <Icon icon="ph:arrow-left-bold" />
          </button> </div>
        </form>
      </div>

      {isLoading && (
        <>
          <div className="spinnerCss"></div>
          <ThreeCircles
            height="100"
            width="100"
            color="#fff"
            wrapperStyle={{}}
            wrapperClass="inner_spinner_css"
            visible={true}
            ariaLabel="three-circles-rotating"
            outerCircleColor=""
            innerCircleColor=""
            middleCircleColor=""
          />
        </>
      )}
    </>
  );
};

export default EnquiryForm;

import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import WOW from "wowjs";
import { Icon } from "@iconify/react";
import { Helmet } from "react-helmet-async";
const JindalSAW = (props) => {
  useEffect(() => {
    const wow = new WOW.WOW({
      live: false,
    });
    wow.init();
  }, []);

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
  <Helmet>
  <title>Jindal SAW | Reversethought</title>
  <meta name="description" content="Reverse Thought is a multi-disciplinary Internet and Digital Marketing agency in Mumbai, India specializes in web and UI/UX design, Apps, Videos, and much more." />
  <meta name="robots" content="max-image-preview:large" />
  <link rel="canonical" href="https://www.reversethought.com/jindal-SAW" />
  <meta property="og:title" content="Jindal SAW | Reversethought" />
  <meta property="og:description" content="Reverse Thought is a multi-disciplinary Internet and Digital Marketing agency in Mumbai, India specializes in web and UI/UX design, Apps, Videos, and much more."/>
  <meta property="og:url" content="https://www.reversethought.com/jindal-SAW" />
  <meta name="twitter:title" content="Jindal SAW | Reversethought" />
  <meta name="twitter:description" content="Reverse Thought is a multi-disciplinary Internet and Digital Marketing agency in Mumbai, India specializes in web and UI/UX design, Apps, Videos, and much more." />
    
  <meta name="google-site-verification" content="HoCjksaLBwM7qPH8Nt-Rzrqa5K02wXYiJwFSEMC4j8g" />
  <meta name="p:domain_verify" content="f307b6f44d53f755ef67d9a9a3666de7" />
  <meta name="keywords" content="top social media marketing agencies in mumbai,best digital marketing company in mumbai,online lead generation companies,top creative agency india,creative agencies in mumbai,top digital agency in mumbai 2022,digital creative agency mumbai,digital creative agency india,digital agency mumbai,creative agency in mumbai,creative agency in india,design agency in mumbai" />        
  <meta name="generator" content="All in One SEO (AIOSEO) 4.4.7.1" />
  <meta property="og:site_name" content="Creative Agency In Mumbai | Branding Agency India - Reverse Thought" />
</Helmet>

      <section className="ourWork">
        <div className="content_container">
          <h1 className="projectHeading colorBlack fw-800 text-center wow animate__animated animate__fadeIn">
            Jindal SAW
          </h1>
          <img
            src="/images/works/jindal/mainbanner.jpg"
            alt="jindalSAW"
            className="img-fluid wow animate__animated animate__fadeIn"
          />
          <div className="gridSystem">
            <div className="gridSystemInner">
              {/* <h2 className="colorRed fw-800 wow animate__animated animate__fadeIn">    ---- 02/01/24
                Brief
              </h2>
              <p className="colorGrey fw-400 wow animate__animated animate__fadeInLeft">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur.
              </p> */}
              <div className="row m-0 briefDetails wow animate__animated animate__fadeIn">
                <div className="col-sm-6 p-0 briefDetailsInner wow animate__animated animate__fadeInLeft">
                  <h4 className="colorBlack fw-800">Client</h4>
                  <span className="colorGrey fw-400">Jindal SAW</span>
                </div>
                <div className="col-sm-6 p-0 briefDetailsInner wow animate__animated animate__fadeInLeft">
                  <h4 className="colorBlack fw-800">Industry</h4>
                  <span className="colorGrey fw-400">Corporate</span>
                </div>
                <div className="col-sm-6 p-0 briefDetailsInner wow animate__animated animate__fadeInRight">
                  <h4 className="colorBlack fw-800">Work</h4>
                  <span className="colorGrey fw-400">
                    Creative Design & Digital Marketing
                  </span>
                </div>
              </div>
            </div>
            <div className="gridSystemInner">
              <div className="d-flex customGap">
                <img
                  src="/images/works/jindal/fscd.jpg"
                  alt="jindalSAW"
                  className="img-fluid w-100 wow animate__animated animate__fadeInRight"
                />
                <img
                  src="/images/works/jindal/sccd.jpg"
                  alt="jindalSAW"
                  className="img-fluid w-100 wow animate__animated animate__fadeInRight  animate__delay-0.9s"
                  style={{ marginTop: 220 }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="serviceImageSection"
        onMouseEnter={props.mouseEnterOnBlack}
        onMouseLeave={props.mouseLeaveOnBlack}
      >
        <div className="content_container">
          <div className="row m-0">
            <div className="col-12 p-0 fullWidth wow animate__animated animate__slideInDown">
              <img
                src="/images/works/jindal/secondbanner.jpg"
                alt="service1"
                className="w-100"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="ourWorkSlider wow animate__animated animate__slideInLeft">
        <div className="content_container">
          <h2 className="sectionHead colorBlack fw-800 wow animate__animated animate__fadeIn">
            <img src="/images/icons/line.jpg" alt="line" />
            More Works
          </h2>
          <Slider {...settings}>
            <div>
              <div className="sliderInner">
              <a href="/mpuh" className="text-decoration-none">
                <div className="imgHoverEffect position-relative">
                  <a
                    href="/mpuh"
                    className="colorWhite fw-800"
                    onMouseEnter={props.onMouseEnterSmall}
                    onMouseLeave={props.onMouseLeaveSmall}
                  >
                    View Projects &nbsp;
                    <img
                      src="/images/icons/topArrowWhite.svg"
                      alt="toparrow"
                      className="img-fluid"
                    />
                  </a>
                  <img src="/images/cards/digital/mpuh.jpg" alt="work" />
                </div>
                <h2 className="colorBlack fw-800">MPUH</h2>
                <span className="colorGrey fw-400">Digital Marketing</span>
                </a>
              </div>
            </div>
            <div>
              <div className="sliderInner">
              <a href="/speak-health2" className="text-decoration-none">
                <div className="imgHoverEffect position-relative">
                  <a
                    href="/speak-health2"
                    className="colorWhite fw-800"
                    onMouseEnter={props.onMouseEnterSmall}
                    onMouseLeave={props.onMouseLeaveSmall}
                  >
                    View Projects &nbsp;
                    <img
                      src="/images/icons/topArrowWhite.svg"
                      alt="toparrow"
                      className="img-fluid"
                    />
                  </a>
                  <img src="/images/cards/digital/sh.jpg" alt="work" />
                </div>
                <h2 className="colorBlack fw-800">Speak Health</h2>
                <span className="colorGrey fw-400">Digital Marketing</span>
                </a>
              </div>
            </div>
            <div>
              <div className="sliderInner">
              <a href="/sky-greens" className="text-decoration-none">
                <div className="imgHoverEffect position-relative">
                  <a
                    href="/sky-greens"
                    className="colorWhite fw-800"
                    onMouseEnter={props.onMouseEnterSmall}
                    onMouseLeave={props.onMouseLeaveSmall}
                  >
                    View Projects &nbsp;
                    <img
                      src="/images/icons/topArrowWhite.svg"
                      alt="toparrow"
                      className="img-fluid"
                    />
                  </a>
                  <img src="/images/cards/digital/skygreens.jpg" alt="work" />
                </div>
                <h2 className="colorBlack fw-800">Skygreens</h2>
                <span className="colorGrey fw-400">Digital Marketing</span>
                </a>
              </div>
            </div>
            <div>
              <div className="sliderInner">
              <a href="/speed-of-sound" className="text-decoration-none">
                <div className="imgHoverEffect position-relative">
                  <a
                    href="/speed-of-sound"
                    className="colorWhite fw-800"
                    onMouseEnter={props.onMouseEnterSmall}
                    onMouseLeave={props.onMouseLeaveSmall}
                  >
                    View Projects &nbsp;
                    <img
                      src="/images/icons/topArrowWhite.svg"
                      alt="toparrow"
                      className="img-fluid"
                    />
                  </a>
                  <img src="/images/cards/digital/sos.jpg" alt="work" />
                </div>
                <h2 className="colorBlack fw-800">Speed Of Sound</h2>
                <span className="colorGrey fw-400">Digital Marketing</span>
                </a>
              </div>
            </div>
          </Slider>
          <Link
            to="/works"
            className="unsetAll"
            onClick={() => window.scrollTo(0, 0)}
          >
            <span className="commonViewAll">View Our Projects</span>
            <Icon icon="clarity:arrow-line" />
          </Link>
        </div>
      </section>
    </>
  );
};

export default JindalSAW;

import React from "react";
import { Helmet } from "react-helmet-async";

const PrivacyPolicy = () => {
  return (
    <>
  <Helmet>
    <title>Privacy Policy | Reverse Thought</title>
    <meta name="description" content="Learn how Reverse Thought's privacy policy ensures the protection of your valuable data. Discover our commitment to safeguarding your privacy and maintaining transparency." />                          
    <link rel="canonical" href="https://www.reversethought.com/privacy-policy"/>
    <meta property="og:title" content="Privacy Policy | Reverse Thought" />
    <meta property="og:description" content="Learn how Reverse Thought's privacy policy ensures the protection of your valuable data. Discover our commitment to safeguarding your privacy and maintaining transparency."/>
    <meta property="og:url" content="https://www.reversethought.com/privacy-policy" />
    <meta name="twitter:title" content="Privacy Policy | Reverse Thought" />
    <meta name="twitter:description" content="Learn how Reverse Thought's privacy policy ensures the protection of your valuable data. Discover our commitment to safeguarding your privacy and maintaining transparency." />
      

    <meta name="google-site-verification" content="HoCjksaLBwM7qPH8Nt-Rzrqa5K02wXYiJwFSEMC4j8g" />
    <meta name="p:domain_verify" content="f307b6f44d53f755ef67d9a9a3666de7" />
    <meta name="keywords" content="top social media marketing agencies in mumbai,best digital marketing company in mumbai,online lead generation companies,top creative agency india,creative agencies in mumbai,top digital agency in mumbai 2022,digital creative agency mumbai,digital creative agency india,digital agency mumbai,creative agency in mumbai,creative agency in india,design agency in mumbai" />        
    <meta name="generator" content="All in One SEO (AIOSEO) 4.4.7.1" />
    <meta property="og:site_name" content="Creative Agency In Mumbai | Branding Agency India - Reverse Thought" />
  </Helmet>

      <section
        className="bread bg-light py-5 "
        style={{ backgroundColor: "#f7f7f7" }}
      >
        <div className="container text-start">
          <h1
            className="text-dark"
            style={{ fontSize: "20px", lineHeight: "26px" }}
          >
            Privacy Policy
          </h1>
        </div>
      </section>
      <section
        className="policy-area"
        style={{ padding: "90px 0 41px", minHeight: "70vh" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div className="entry-content">
                <p>
                  This is the Privacy Policy of the reversethought.com website
                  (and any successor or other domains that we operate) and all
                  content, services and products, including our mobile
                  applications, that we provide (collectively, the
                  “Application”). The Application is owned and operated by
                  Ideaform Inc. (together with our affiliates and subsidiaries,
                  “Ideaform”, “we”, “us”, “our” and terms of similar meaning).
                  It describes the personal information that we collect from
                  users of our Application as part of the normal operation of
                  our Application, and how we use and disclose this information.
                  Capitalized terms used but not defined in this Privacy Policy
                  have the meanings in our Terms of Service.
                </p>
                <p>
                  In this Privacy Policy, when we refer to “Users”, we mean our
                  customers who use our Services to create their own Portfolios,
                  and when we refer to “End Users” we mean the users, visitors
                  and customers of our Users’ Portfolios.
                </p>
                <p>
                  This Privacy Policy describes what we do with personal
                  information that we collect and use for our own purposes
                  (i.e., where we are a controller), such as User Account
                  information and information about how you use and interact
                  with our Services, including information you submit to our
                  customer support as well as certain information relating to
                  our Users’ End Users.
                </p>
                <p>
                  This Privacy Policy describes what we do with personal
                  information that we collect and use for our own purposes
                  (i.e., where we are a controller), such as User Account
                  information and information about how you use and interact
                  with our Services, including information you submit to our
                  customer support as well as certain information relating to
                  our Users’ End Users.
                </p>
                <p>
                  We host and process Content for our Users, for their own use
                  and for the use of their End Users. Our Users tell us what to
                  do with their Content in relation to the Service, and in
                  accordance with the Agreement and the operation of our Service
                  we follow their instructions. This Privacy Policy does not
                  describe what we do with Content on our Users’ instructions
                  (i.e., as their processor). If you are an End User of one of
                  our Users’ Portfolios and want to know how that User handles
                  your information, you should check its privacy policy. If you
                  want to know about what we do for our own purposes, read on.
                  If you are a User, see our Data Processing Policy to learn
                  more about how we process User Content on your instructions or
                  with your permission.
                </p>
                <p>
                  We use cookies and similar technologies. Our Cookie Policy
                  describes what we do in that regard.
                </p>
                <p>
                  By accepting this Privacy Policy in registration or by using
                  our Services, you expressly consent to our collection, use and
                  disclosure of your personal information in accordance with
                  this Privacy Policy. This Privacy Policy is incorporated into
                  and subject to our Terms of Service.
                </p>
                <p>
                  <strong>How We Use Your Information</strong>
                </p>
                <p>
                  We use personal information in the file we maintain about you,
                  and other information we obtain from your current and past
                  activities on the Application, to provide to you the services
                  offered by the Application; resolve service disputes;
                  troubleshoot problems; measure consumer interest in our
                  products and services; inform you about online and offline
                  offers, products, services, events and updates; deliver
                  information to you that, in some cases, is relevant to your
                  interests, such as product news; customize your experience;
                  detect and protect us against error, fraud and other criminal
                  activity; enforce our Terms of Service; provide you with
                  system or administrative messages, and as otherwise described
                  to you at the time of collection.
                </p>
                <p>
                  We may also use personal information about you to improve our
                  marketing and promotional efforts, to analyze Application
                  usage, to improve our content and product offerings, and to
                  customize the Application’s content, layout, and services.
                  These uses improve the Application and better tailor it to
                  meet your needs, so as to provide you with a smooth,
                  efficient, safe and customized experience while using the
                  Application.
                </p>
                <p>
                  Please see below under “How we Retain your Personal
                  Information” for information on what we do to account
                  information when you terminate your account with us.
                </p>
                <p>
                  <strong>What Information Do We Collect?</strong>
                </p>
                <p>
                  Our primary purpose in collecting personal information from
                  you is to provide you with a safe, smooth, efficient, and
                  customized experience. This allows us to provide services and
                  features that most likely meet your needs, and to customize
                  our service to make your experience safer and easier. We only
                  collect personal information about you that we consider
                  necessary for achieving this purpose.
                </p>
                <p>
                  In general, you can browse the Application without telling us
                  who you are or revealing any personal information about
                  yourself. Once you become a User, we require you to provide
                  various contact and identity information and other personal
                  information as indicated on the relevant forms on the
                  Application, and you are no longer anonymous to us. Where
                  possible, on these forms we indicate which fields are required
                  and which fields are optional. In addition, as you use the
                  Application, you can from time to time enter or send to us
                  personal information.
                </p>
                <p>
                  You always have the option to not provide information by
                  choosing not to become a User or by not using the particular
                  feature of the Application for which the information is being
                  collected.
                </p>
                <p>
                  We also automatically track certain information about you
                  based upon your behaviour on our Application. We use this
                  information to do internal research on our users’
                  demographics, interests, and behaviour to better understand,
                  protect and serve you and our community. This information may
                  include the URL that you just came from (whether this URL is
                  on our Application or not), which URL you next go to (whether
                  this URL is on our Application or not), your computer browser
                  information, your IP address and your location.
                </p>
                <p>
                  If you are an End User of our Users’ Portfolios, we also get
                  information about your interactions with their Portfolios,
                  though we use this in anonymous, aggregated or pseudonymized
                  form which does not focus on you individually. We use this
                  data to evaluate, provide, protect or improve our Services
                  (including by developing new products and services).
                </p>
                <p>
                  Some of this information is collected automatically using
                  cookies and similar technologies when you use our Services and
                  our Users’ Portfolios. We let our Users control what cookies
                  and similar technologies are used through their Portfolios
                  (except those we need to use to provide the Services properly,
                  such as for performance or security related reasons). You can
                  read more about our use of cookies in our Cookie Policy. Some
                  of this information is similarly collected automatically
                  through your browser or from your device.
                </p>
                <p>
                  We also use Twitter’s Conversion Tracking and Tailored
                  Audiences product – by using the Application you are
                  permitting Twitter to collect non-identifying data for
                  analytics purposes. We use this data to improve upon our
                  services and audience; this anonymous data is only used to
                  understand our user base and to assess features and potential
                  advertising content to enhance the Application experience.
                </p>
                <p>
                  If you use a Third Party Service (such as Google) to register
                  for an Account, the Third Party Service may provide us with
                  your Third Party Service account information on your behalf,
                  such as your name and email address (we don’t store passwords
                  you use to access Third Party Services). Your privacy settings
                  on the Third Party Service normally control what they share
                  with us. Make sure you are comfortable with what they share by
                  reviewing their privacy policies and, if necessary, modifying
                  your privacy settings directly on the Third Party Service.
                </p>
                <p>
                  We do not collect or save your credit card information when
                  you use your credit card to subscribe to our Application –
                  when you subscribe to our Application, the payment processors
                  that we use handle all credit card information. However, If
                  you subscribe, we do obtain limited information about your
                  payment card from our payment processors, such as the last
                  four digits, the country of issuance and the expiration date.
                  Currently, our payment processors are Braintree, PayPal and
                  Bambora. Please consult their terms of use and privacy
                  policies for more information about they use your personal
                  information.
                </p>
                <p>
                  Following your registration on the Application, you can review
                  and change your information in your account area. If you
                  disclose personal information on the Application and wish to
                  have it removed, please contact us at the support contact
                  information posted on the Application.
                </p>
                <p>
                  <strong>Our Disclosure of Your Information</strong>
                </p>
                <p>
                  We will not sell or rent your personally identifiable
                  information to third parties without your explicit consent.
                  The following describes some of the ways that your information
                  may be disclosed in the normal scope of business to provide
                  our services:
                </p>
                <p>
                  Anonymized Aggregated Data. We aggregate and anonymize data
                  and use and disclose such information for a variety of
                  purposes. However, in these situations, we do not disclose any
                  information that could be used to identify you personally.
                </p>
                <p>
                  End Users. We may share with our Users data regarding usage by
                  End Users of their Portfolios. For example, we may provide a
                  User with information about what web page the End User visited
                  before coming to their Portfolios and how their End Users
                  interacted with their Portfolios. This is so Users can analyze
                  the usage of their Portfolios and improve their services.
                </p>
                <p>
                  Public Areas of the Application. All of your activities in the
                  public areas of the Application will be identifiable to your
                  User ID, and other people can see your published content.
                </p>
                <p>
                  Subsidiaries and Affiliates; Service Providers. We may from
                  time to time use the services of affiliates, subsidiaries and
                  unrelated service providers in the operation of the
                  Application, and may disclose personal information to them in
                  the course of our use of their services. For example, we may
                  use the services of third-party hosting companies to host the
                  operation of the Application. This may involve the hosting of
                  data, including personal information, on servers operated by
                  those hosting companies. We also partner with third party
                  publishers, advertising networks and service providers to
                  manage our ads on other sites. Our third party partners may
                  set cookies on your device or browser to gather information
                  about your activities on the Services and other sites you
                  visit, in order to provide you with Ideaform ads. For example,
                  if you visit Ideaform and also use a social media platform,
                  you may see a Ideaform ad in your social media newsfeed or
                  timeline. See our Cookie Policy for more information. We take
                  care to use only service providers that we believe are
                  reputable and able to live up to our and your expectations,
                  including about the handling of confidential information.
                </p>
                <p>
                  Legal Requests; Emergencies; Jurisdictions. We cooperate with
                  law enforcement inquiries and demands for information that are
                  made under force of law. We are also cognizant of the need to
                  address emergencies. Therefore, we may disclose User and End
                  User personal information (a) to any governmental authority as
                  part of an investigation to determine our compliance with any
                  applicable law, rule, or regulation (including privacy laws,
                  rules, and regulations), (b) in response to a court order,
                  subpoena, discovery request, or other lawful judicial or
                  administrative proceeding, (c) as otherwise required under any
                  applicable law, rule, or regulation, and (d) in good faith, to
                  protect or defend our rights or property or those of other
                  users or End Users.
                </p>
                <p>
                  We may also disclose personal information to the acquiror or
                  its agents in the course of the sale of our business. If we do
                  this, the disclosure will be subject to confidentiality
                  arrangements customary in such transactions.
                </p>
                <p>
                  <strong>Security</strong>
                </p>
                <p>
                  Ideaform Inc. strives to protect your personal information.
                  For security of transactions, we use the Secure Sockets Layer
                  (SSL) protocol, which encrypts any personal information you
                  enter into registration forms on the Application. The
                  encryption process protects your information, by scrambling it
                  before it is sent to us from your computer. We also make
                  commercially reasonable effort to ensure the security of your
                  personal information on our system. Unfortunately, no data
                  transmission over the Internet can be guaranteed to be 100%
                  secure. As a result, while we strive to protect your personal
                  information, we cannot warrant the security of any information
                  you transmit to us.
                </p>
                <p>
                  <strong>How We Retain Your Personal Information</strong>
                </p>
                <p>
                  We retain personal information regarding you or your use of
                  the Services for as long as your Account is active or for as
                  long as needed to provide you or our Users with the Services.
                  We also retain personal information for as long as necessary
                  to achieve the purposes described in this Privacy Policy, for
                  example, to comply with our legal obligations, to protect us
                  in the event of disputes and to enforce our agreements and to
                  protect our and others’ interests.
                </p>
                <p>
                  The precise periods for which we keep your personal
                  information vary depending on the nature of the information
                  and why we need it. Factors we consider in determining these
                  periods include the minimum required retention period
                  prescribed by law or recommended as best practice, the period
                  during which a claim can be made with respect to an agreement
                  or other matter, whether the personal information has been
                  aggregated or pseudonymized, and other relevant criteria. For
                  example, the period we keep your email address is connected to
                  how long your Account is active, while the period for which we
                  keep a support message is based on how long has passed since
                  the last submission in the thread.
                </p>
                <p>
                  As Users may come back to us after an Account becomes
                  inactive, we don’t immediately delete your personal
                  information when a trial period expires or you cancel all paid
                  Services. Instead, we keep your personal information for a
                  reasonable period of time, so it will be there for you if you
                  come back.
                </p>
                <p>
                  You may delete your Account by contacting us at{" "}
                  <a href="mailto:contact@reversethought.com">
                    contact@reversethought.com
                  </a>{" "}
                  and we will delete the personal information it holds about you
                  (unless we need to retain it for the purposes set out in this
                  Privacy Policy).
                </p>
                <p>
                  Please note that in the course of providing the Services, we
                  collect and maintain aggregated, anonymized or de-personalized
                  information which we may retain indefinitely.
                </p>
                <p>
                  <strong>Other Information Collectors</strong>
                </p>
                <p>
                  Except as otherwise expressly included in this Privacy Policy,
                  this document only addresses the use and disclosure of
                  information we collect from you. To the extent that you
                  disclose your information to other parties through the
                  Application, including Third Party Services, different rules
                  may apply to their use, collection and disclosure of the
                  personal information you disclose to them. Since we do not
                  control the information use, collection or disclosure policies
                  of third parties, you are subject to their privacy policies.
                  We encourage you to ask questions before you disclose your
                  personal information to others.
                </p>
                <p>
                  <strong>End Users’ Personal Information</strong>
                </p>
                <p>
                  Users who have created a Portfolio using the Services are
                  responsible for what they do with the personal information
                  they collect, directly or through our Services, about their
                  End Users. This section is directed to our Users concerning
                  their End Users.
                </p>
                <p>a. Your Relationship with End Users</p>
                <p>
                  If you’re one of our Users, you may collect personal
                  information about your End Users. For example, during an
                  eCommerce checkout you may ask your End Users to provide their
                  name, address, email address and payment information so that
                  you can complete their orders. You may also use cookies and
                  similar technologies to analyze usage and other trends.
                </p>
                <p>
                  You’re solely responsible for complying with any laws and
                  regulations that apply to your collection and use of your End
                  Users’ information, including personal information you collect
                  about them from us or using our functionality or cookies or
                  similar technologies.
                </p>
                <p>
                  You must publish your own privacy and cookie policy and comply
                  with them.
                </p>
                <p>
                  We’re not liable for your relationship with your End Users or
                  how you collect and use personal information about them (even
                  if you collect it from us or using our functionality or
                  cookies or similar technologies) and we won’t provide you with
                  any legal advice regarding such matters.
                </p>
                <p>b. End User Payment Information</p>
                <p>
                  Your End Users’ payment information may be processed via third
                  party eCommerce Payment Processors with which you integrate
                  your Account, in accordance with such eCommerce Payment
                  Processors’ terms and policies. We transmit your End Users’
                  complete payment information when they initially provide or
                  update it only so that we can pass it along to the eCommerce
                  Payment Processors you agree to use. We don’t collect or store
                  your End Users’ payment information except as described above.
                </p>
                <p>
                  <strong>Your Rights and Choices</strong>
                </p>
                <p>
                  Where applicable law requires (and subject to any relevant
                  exceptions under law), you may have the right to access,
                  update, change or delete personal information.
                </p>
                <p>
                  You can access, update, change or delete personal information
                  (or that of your End Users) either directly in your Account or
                  by contacting us at contact@reversethought.com to request the
                  required changes. You can exercise your other rights
                  (including deleting your Account) by contacting us at the same
                  email address.
                </p>
                <p>
                  You can also elect not to receive marketing communications by
                  changing your preferences in your Account or by following the
                  unsubscribe instruction in such communications.
                </p>
                <p>
                  Please note that, for technical reasons, there is likely to be
                  a delay in deleting your personal Information from our systems
                  when you ask us to delete it. We also will retain personal
                  Information in order to comply with the law, protect our and
                  others’ rights, resolve disputes or enforce our legal terms or
                  policies, to the extent permitted under applicable law.
                </p>
                <p>
                  You may have the right to restrict or object to the processing
                  of your personal information or to exercise a right to data
                  portability under applicable law. You also may have the right
                  to lodge a complaint with a competent supervisory authority,
                  subject to applicable law. If you are subject to EU data
                  protection laws, we suggest you lodge any such complaints with
                  our lead supervisory authority:
                </p>
                <p>
                  Mumbai (Registered Address)
                  <br />
                  C 601, Kingston Heights, Chincholi Bunder
                  <br />
                  Road, Malad West, Mumbai-400064, India.
                </p>
                <p>
                  Additionally, if we rely on consent for the processing of your
                  personal information, you have the right to withdraw it at any
                  time and free of charge. When you do so, this will not affect
                  the lawfulness of the processing before your consent
                  withdrawal.
                </p>
                <p>
                  Our Cookie Policy explains how you can manage cookies and
                  similar technologies.
                </p>
                <p>
                  If you are an End User of one of our Users’ Portfolios, you
                  should contact them to exercise your rights with respect to
                  any information they hold about you.
                </p>
                <p>
                  <strong>Data Transfers</strong>
                </p>
                <p>
                  Personal information that you submit through the Services may
                  be transferred to countries other than where you live, such
                  as, for example, to servers in the United States. We also
                  store personal information locally on the devices you use to
                  access the Services.
                </p>
                <p>
                  Your personal information may be transferred to countries that
                  do not have the same data protection laws as the country in
                  which you initially provided the information.
                </p>
                <p>
                  In such cases, we continue to protect the information with
                  appropriate safeguards, but it may be subject to the legal
                  jurisdiction of those countries and governmental authorities
                  in those countries.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PrivacyPolicy;

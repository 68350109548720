import React from "react";
import { useEffect } from "react";
import { Accordion } from "react-bootstrap-accordion";
import WOW from "wowjs";
import { Helmet } from "react-helmet-async";
import { Icon } from "@iconify/react";
const Faq = (props) => {
  useEffect(() => {
    const wow = new WOW.WOW({
      live: false,
    });
    wow.init();
  }, []);

  return (
    <>

     <Helmet>
        <title>Faq | Reversethought</title>
        <meta name="description" content="Looking for answers to your queries about Reverse Thought? Check out FAQ Section and find all the information you need about our company, product, and services. " />        
        <link rel="canonical" href="https://www.reversethought.com/faq"/>
        <meta property="og:title" content="Faq | Reversethought" />
        <meta property="og:description" content="Looking for answers to your queries about Reverse Thought? Check out FAQ Section and find all the information you need about our company, product, and services."/>
        <meta property="og:url" content="https://www.reversethought.com/faq" />
        <meta name="twitter:title" content="Faq | Reversethought" />
        <meta name="twitter:description" content="Looking for answers to your queries about Reverse Thought? Check out FAQ Section and find all the information you need about our company, product, and services." />
            
        <meta name="google-site-verification" content="HoCjksaLBwM7qPH8Nt-Rzrqa5K02wXYiJwFSEMC4j8g" />
        <meta name="p:domain_verify" content="f307b6f44d53f755ef67d9a9a3666de7" />
        <meta name="keywords" content="top social media marketing agencies in mumbai,best digital marketing company in mumbai,online lead generation companies,top creative agency india,creative agencies in mumbai,top digital agency in mumbai 2022,digital creative agency mumbai,digital creative agency india,digital agency mumbai,creative agency in mumbai,creative agency in india,design agency in mumbai" />        
        <meta name="generator" content="All in One SEO (AIOSEO) 4.4.7.1" />
        <meta property="og:site_name" content="Creative Agency In Mumbai | Branding Agency India - Reverse Thought" />
      </Helmet>

      <section
        className="ourExpertiseSection wow"
        style={{
          padding: "padding: 6.8vw 0 1vw !important;",
        }}
        onMouseEnter={props.mouseEnterOnBlack}
        onMouseLeave={props.mouseLeaveOnBlack}
      >
        <div className="content_container">
          <h2 className="sectionHead colorWhite text-white fw-800 wow">
            <img src="/images/icons/line-white.jpg" alt="line" />
            FAQs
          </h2>
          <div className="accordianParent wow animate__animated animate__fadeIn animation_delay_300ms">
            <Accordion title="How long will it take to build my website?">
              <p>
                The time needed for building your website depends upon each
                client's complexities and unique requirements. However, building
                a website from scratch can take from a few weeks to a couple of
                months. You can contact us to know the estimated time to develop
                your website.
              </p>
            </Accordion>
            <Accordion title="Can I expect ongoing maintenance from your website development agency in India?">
              <p>
                We provide website maintenance services to ensure that your
                website performance is optimum. It includes monitoring your
                website traffic, securing sites and updating content. You can
                expect your website to stay on top by relying on our in-house
                experts.
              </p>
            </Accordion>
            <Accordion title="What is your cost structure for website development services?">
              <p>
                Our website development services have no set cost; they may vary
                from client to client. You can contact us, inform us about the
                size, complexity and functionality you want in your website.
                Basis the details you share, we can provide you with an
                estimated project cost for our web development services.
              </p>
            </Accordion>
            <Accordion title="Can I make changes after I build my website?">
              <p>
                You might face instances when you may not like or need to alter
                certain things on your website. In that case, we can make the
                necessary alterations so that you get the desired results on
                your website. Feel free to share your concerns with us and get
                an expert solution.
              </p>
            </Accordion>
            <Accordion title="Will my website be mobile-friendly?">
              <p>
                We build a mobile-friendly website so your users can easily
                access your website via smartphones. Moreover, we run a
                mobile-friendly test to uncover and resolve any issues before
                delivering to you.
              </p>
            </Accordion>
            <Accordion title="Does your website agency optimize websites for search engines?">
              <p>
                Our website development service based in Mumbai, India also
                helps clients optimize their website for search engines. We work
                on improving the website’s visibility on search engine result
                pages (SERPs) to drive more traffic to the website and increase
                sales.
              </p>
            </Accordion>
          </div>
        </div>
      </section>
    </>
  );
};

export default Faq;
